<!-- ConfirmationDialog.vue -->
<template>
    <Dialog :visible.sync="visible" @hide="handleHide" modal>
        <div style="text-align: center">
            <p>{{ message }}</p>
        </div>
        <div class="p-dialog-footer button-container">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text p-button-secondary" @click="handleHide" />
            <Button label="Aceptar" icon="pi pi-check" class="p-button-text p-button-primary" @click="handleConfirm" />
        </div>
    </Dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

export default defineComponent({
    components: {
        Dialog,
        Button
    },
    props: ['visible', 'message'],
    emits: ['hide', 'confirm'],
    methods: {
        handleConfirm() {
            this.$emit('confirm');
        },
        handleHide() {
            this.$emit('hide');
        }
    }
});
</script>

<style scoped>
.button-container {
    display: flex;
    justify-content: center;
    gap: 16px;
    /* Adds 16px space between the buttons */
    margin-top: 16px;
    /* Adds some top margin for better spacing */
}

.p-button-secondary {
    background-color: #f44336;
    /* Red background for cancel button */
    color: white;
}

.p-button-primary {
    background-color: #4caf50;
    /* Green background for accept button */
    color: white;
}
</style>
