import { createStore } from 'vuex'
import AUTH from './modules/auth'
import toastr from "toastr";
import 'toastr/build/toastr.css';
export default createStore({
  state: {
	dataFilter:{
		column:"gbusage",
		mode:"DESC",
		text:""
	  },
	arrayData: [],
  },
  getters: {
	filteredTerminals: (state: { arrayData: any; dataFilter: any }) => {
		let data = state.arrayData;
		if (state.dataFilter.text.length > 1) {
		  data = data.filter(
			(r: any) =>
			  String(r.siteId)
				.toLowerCase()
				.includes(state.dataFilter.text.toLowerCase())
				||
			  String(r.addressRoute)
				.toLowerCase()
				.includes(state.dataFilter.text.toLowerCase())
				||
			  String(r.esn)
				.toLowerCase()
				.includes(state.dataFilter.text.toLowerCase())
				||
			  String(r.subscribers_name)
				.toLowerCase()
				.includes(state.dataFilter.text.toLowerCase())
				||
			  String(r.principal_name)
				.toLowerCase()
				.includes(state.dataFilter.text.toLowerCase())
		  );
		}
		if (data) {
		  if (data.length>0) {
			  data.forEach((element:any) => {
				element.show=false
			  });
			}
		}
		return data;
	  }
  },
  mutations: {
	SET_arrayData: (state: { arrayData: any }, values: any) => {
		state.arrayData = values;
	  },
	SET_FILTER_TERMINALS:(state: { dataFilter: any }, data: any) => {
		state.dataFilter[data['filter']] = data.value;
	},
  },
  actions: {
    showToast(state, toast) {
			const message = toast.message?toast.message:(typeof toast === 'string' ? toast:'' )
			const title = toast.title?toast.title:'Ses:';
			const overrides = toast.overrides?toast.overrides:{};
			
			switch (toast.type) {
				case 'warning': 
					toastr.warning(message, title, overrides)
					break;
				case 'success': 
					toastr.success(message, title, overrides)
					break;
				case 'info': 
					toastr.info(message, title, overrides)
					break;
				case 'error':
				default:
					toastr.error(message, title, overrides)
					break;
			}
		}
  },
  modules: {
    AUTH
  }
})
