import request from "@/utils/general-functions";
const obj = {
  enviarEmails(values: any) {
    return request({
      url: "/application/send-email",
      method: "post",
      data: values,
    });
  },
};2
export default obj;
