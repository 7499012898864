<template>
  <PopupRequestHigh @close="closePopup">
    <div class="header-content">
      <img @click="closePopup" style="cursor: pointer; margin-top: -1rem" width="25" height="25"
        src="@/assets/eliminar-50.png" alt="delete-sign--v1" />
      <h1 style="
          text-align: center;
          margin-top: 1rem;
          margin-bottom: 3rem;
          color: white;
          font-weight: 100;
        ">
        {{ $t('message.planta.f32') }}
      </h1>
    </div>

    <!-- Formulario -->
    <form @submit.prevent="submitForm" class="p-grid">
      <div class="p-col-6">
        <div class="p-field">
          <label for="suscriptor" class="white-text">{{ $t('message.planta.c3') }}</label>
          <!-- <input type="text" v-model="form.suscriptor" id="suscriptor" class="p-inputtext"> -->
          <v-select v-model="form.suscriptor" :options="subscriptores" label="name"
            :placeholder="$t('message.planta.c3')" class="custom-select" />
        </div>
      </div>
      <div class="p-col-6">
        <div class="p-field">
          <label for="address" class="white-text">{{ $t('message.planta.c11') }}</label>
          <input type="text" v-model="form.address" id="address" class="p-inputtext">
        </div>
      </div>
      <div class="p-col-6">
        <div class="p-field">
          <label for="latitud" class="white-text">{{ $t('message.planta.c13') }}</label>
          <input type="text" v-model="form.latitud" id="latitud" class="p-inputtext">
        </div>
      </div>
      <div class="p-col-6">
        <div class="p-field">
          <label for="longitud" class="white-text">{{ $t('message.planta.c12') }}</label>
          <input type="text" v-model="form.longitud" id="longitud" class="p-inputtext">
        </div>
      </div>
      <div class="p-col-6">
        <div class="p-field">
          <label for="siteId" class="white-text">{{ $t('message.planta.c2') }}</label>
          <input type="text" v-model="form.siteId" id="siteId" class="p-inputtext">
        </div>
      </div>
      <div class="p-col-6">
        <div class="p-field">
          <label for="numeroSerie" class="white-text">{{ $t('message.planta.c6') }}</label>
          <input type="text" v-model="form.numeroSerie" id="numeroSerie" class="p-inputtext">
        </div>
      </div>
      <div class="p-col-6">
        <div class="p-field">
          <label for="modelType" class="white-text">{{ $t('message.planta.c4') }}</label>
          <input type="text" v-model="form.modelType" id="modelType" class="p-inputtext">
        </div>
      </div>
      <div class="p-col-6">
        <div class="p-field">
          <label for="planServicio" class="white-text">{{ $t('message.planta.c10') }}</label>
          <!-- <input type="text" v-model="form.planServicio" id="planServicio" class="p-inputtext"> -->
          <v-select v-model="form.planServicio" :options="planesServicio" label="name"
            :placeholder="$t('message.planta.f13')" class="custom-select" />
        </div>
      </div>

      <div class="p-col-12 p-md-6 button-group">
        <button type="submit" class="p-button p-button-primary">{{ $t('message.planta.f33') }}</button>
        <button type="button" @click="closePopup" class="p-button p-button-secondary">{{ $t('message.planta.f34')
          }}</button>
      </div>
    </form>
  </PopupRequestHigh>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PopupRequestHigh from "../../../components/planta-conte/PopupRequestHigh.vue";
import store from "../../../store";
import "@/assets/sass/StylesPlanta.scss";
import { mapState } from "vuex";
import Map from "../../../components/map/index.vue";
import vSelect from "vue-select";

export default defineComponent({
  name: "requestHigh",
  props: [],
  emits: ["close"],
  components: {
    PopupRequestHigh,
    Map,
    vSelect,
  },
  data() {
    return {
      form: {
        suscriptor: "",
        address: "",
        latitud: "",
        longitud: "",
        siteId: "",
        numeroSerie: "",
        modelType: "",
        planServicio: "",
        transaccion: 'Alta',
        user_vno_id: null,
        user_vno: null
        // modem: this.$props.terminalSelected.subscribers_name
      },
      planesServicio: [],
      subscriptores: [],
    };
  },
  computed: {
    ...mapState({
      user: (state: any) => state.AUTH.userDecode,
    })
  },
  mounted() {
    this.serviceline()
    this.subscribers()
    // console.log("USER", this.user)
    this.form.user_vno_id = (this.user as any).vno_id
    this.form.user_vno = (this.user as any).vno_name
  },
  methods: {
    closePopup() {
      this.$emit("close");
    },
    submitForm() {
      // console.log("FORM ALTA", this.form)
      // Aquí puedes manejar la lógica para guardar los datos del formulario
      console.log('Formulario enviado:', this.form);
      store.dispatch("CREATE_REQUEST", this.form).then((response) => {
        store.dispatch("showToast", {
          message: `Se ha solicitado un cambio de plan con el siteId: ${this.form.siteId}`,
          type: "success",
        });

        let msj = `<html>
        <head>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    line-height: 1.6;
                }
                .container {
                    max-width: 600px;
                    margin: auto;
                    padding: 20px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    background-color: #f9f9f9;
                }
            </style>
        </head>
        <body>
            <div class="container">
                <p>Buen dia.</p>
                <br>
                <p>Tiene una solicitud Pendiente</p>
                <p><strong>Movimiento: </strong> ${this.form.transaccion} </p>
                <p><strong>Suscriptor: </strong> ${(this.form.suscriptor as any).name} </p>
                <p><strong>Latitud: </strong> ${this.form.latitud}  </p>
                <p><strong>Longitud: </strong> ${this.form.longitud}  </p>
                <p><strong>SiteId: </strong> ${this.form.siteId}  </p>
                <p><strong>Plan de Servicio: </strong> ${(this.form.planServicio as any).name}  </p>
                <br>
                <p>Cuando se ejecute, por favor actualizar el registro de la terminal en la bandeja de solcitudes de BIFROST</p>
                <br>
                <p>Saludos.</p>
                <p>Ordering</p>
            </div>
        </body>
        </html>`

        let subj = `SOLICITUD DE ALTA - CLIENTE ${this.form.user_vno}`

        let data = {
          to: ["yeltsinrhp.93@gmail.com"],
          cc: ["yeltsinrhp.93@hotmail.com"],
          subject: subj,
          message: msj
        }


        store.dispatch("ENVIAR_EMAILS", data).then((response) => {
          store.dispatch("showToast", {
            message: `Se envio un mensaje a los responsables`,
            type: "success",
          });
        })
        this.$emit("close");
      }).catch((error) => {
        store.dispatch("showToast", {
          message: String(error.response.data.message[0]),
          type: "error",
        });
        this.$emit("close");
      });
      // Ejemplo de cómo podrías emitir un evento para cerrar el popup después de guardar
    },
    serviceline() {
      // console.log("SELECT", this.$props.terminalSelected)
      let dataEnd = {
        endpoint: 81,
        type: 1,
        // variables: [
        //   {
        //     name: "$principalId",
        //     value: this.$props.terminalSelected.principal_id
        //   }
        // ]
      }
      // console.log("DATA ENDPOINT", dataEnd)
      store
        .dispatch("SERVICELINEUSERCONFIGV3", dataEnd)
        .then((response) => {
          store.dispatch("showToast", {
            message: "LISTADO LOS PLANES",
            type: "success",
          });
          this.planesServicio = response.data.data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    subscribers() {
      // console.log("SELECT", this.$props.terminalSelected)
      let dataEnd = {
        endpoint: 82,
        type: 1
      }
      store
        .dispatch("SERVICELINEUSERCONFIGV3", dataEnd)
        .then((response) => {
          store.dispatch("showToast", {
            message: "LISTADO DE SUSCRIPTORES",
            type: "success",
          });
          this.subscriptores = response.data.data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
});
</script>

<style lang="scss">
/* Estilos para el contenido del encabezado */
.header-content {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

/* Estilos para el grupo de botones */
.button-group {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
  /* Espacio entre botones */
  flex-wrap: wrap;
  /* Para que los botones se envuelvan si no hay suficiente espacio */
}

/* Estilos para los campos de entrada */
.p-inputtext {
  width: 100%;
  margin-bottom: 1rem;
  color: black;
  /* Color de texto negro */
}

/* Estilo específico para las etiquetas con texto blanco */
.white-text {
  color: white !important;
  /* Color blanco para las etiquetas */
}

/* Estilos para fondos de color azul */
.blue-background {
  background-color: white !important;
  /* Fondo blanco para los selects */
}
</style>
