<template>

  <PopupChangePlan @close="closePopup">
    <div class="header-content">
      <img @click="closePopup" width="25" height="25" src="@/assets/eliminar-50.png" alt="delete-sign--v1"
        class="close-icon" />

      <h1 class="header-title">
        {{ $t('message.planta.f37') }}
      </h1>

    </div>

    <div class="group">
      <h3 style="color: white; line-height: 25px">
        SiteID: {{ terminalSelected.siteId }}
      </h3>
    </div>

    <div class="group">
      <h3 style="color: white; line-height: 25px">
        Modem: {{ terminalSelected.esn }}
      </h3>
    </div>
    <br>
    <form class="popup-form" @submit.prevent="submitForm">
      <div class="group">
        <v-select v-model="form.newPlanServicio" :options="planesServicio" label="name"
          :placeholder="$t('message.planta.f13')" class="custom-select" />
      </div>
      <br>

      <div class="btn-content" style="display: flex; justify-content: space-evenly;">
        <button class="btn-baja" type="submit" id="btn-cerrar-popup2">
          <strong>{{ $t("message.planta.f33") }}</strong>
        </button>
        <button class="btn-baja" @click="closePopup" type="button">
          <strong>{{ $t("message.planta.f34") }}</strong>
        </button>
      </div>
    </form>
  </PopupChangePlan>


</template>

<script lang="ts">
import { defineComponent } from "vue";
import store from "../../../store";
import "@/assets/sass/StylesPlanta.scss";
import { mapState, mapGetters } from "vuex";
import Map from "../../../components/map/index.vue";
import vSelect from "vue-select";
import PopupChangePlan from '@/components/planta-conte/PopupChangePlan.vue';


interface Plan {
  id: number;
  name: string;
  down: number;
  up: number;
  principalId: number;
}

export default defineComponent({
  name: "requestHigh",
  props: ["terminalSelected"],
  emits: ["close"],
  components: {
    PopupChangePlan,
    Map,
    vSelect,
  },
  data() {
    return {
      form: {
        // newPlanServicio: null,
        // transaccion: "",
        // user_vno_id: null,
        // user_vno: null
        newPlanServicio: null as Plan | null,
        transaccion: "",
        user_vno_id: null as string | null,
        user_vno: null as string | null,      
      },
      // planesServicio: [],
      planesServicio: [] as Plan[],
    };
  },
  computed: {
    ...mapState({
      user: (state: any) => state.AUTH.userDecode,
    })
  },
  mounted() {
    this.serviceline()
    this.form = {...this.terminalSelected}
    this.form.user_vno_id = (this.user as any).vno_id
    this.form.user_vno = (this.user as any).vno_name
    this.form.transaccion = "Cambio de Plan"
    console.log("terminalSelected:", this.terminalSelected);
  },
  methods: {
    serviceline() {
      // console.log("SELECT", this.$props.terminalSelected)
      let dataEnd = {
        endpoint: 81,
        type: 1,
        variables: [
          {
            name: "$principalId",
            value: this.$props.terminalSelected.principal_id
          }
        ]
      }
      // console.log("DATA ENDPOINT", dataEnd)
      store
        .dispatch("SERVICELINEUSERCONFIGV3", dataEnd)
        .then((response) => {
          store.dispatch("showToast", {
            message: "LISTADO LOS PLANES",
            type: "success",
          });
          // this.planesServicio = response.data.data;
          // Logs para depuración
          console.log("Planes recibidos:", response.data.data);
          this.planesServicio = this.filterCurrentPlan(response.data.data);

        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    filterCurrentPlan(plans: Plan[]): Plan[] {
      return plans.filter((plan: Plan) => {
        console.log(`Comparando plan ID: ${plan.id} con el plan actual ID: ${this.terminalSelected.servicesPlanId}`);
        return plan.id !== this.terminalSelected.servicesPlanId;
      });
    },

    closePopup() {
      this.$emit("close");
    },
    submitForm() {
      console.log("DATA CAMBIO PLAN", this.form)
      // Aquí puedes manejar la lógica para guardar los datos del formulario
      store.dispatch("CREATE_REQUEST", this.form).then((response) => {
        // console.log(response)
        store.dispatch("showToast", {
          message: `Se ha solicitado un cambio de plan con el siteId: ${(this.form as any).siteId}`,
          type: "success",
        });

        let msj = `<html>
        <head>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    line-height: 1.6;
                }
                .container {
                    max-width: 600px;
                    margin: auto;
                    padding: 20px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    background-color: #f9f9f9;
                }
            </style>
        </head>
        <body>
            <div class="container">
                <p>Buen dia.</p>
                <br>
                <p>Tiene una solicitud Pendiente</p>
                <p><strong>Movimiento: </strong> ${this.form.transaccion} </p>
                <p><strong>SiteId: </strong> ${(this.form as any).siteId}  </p>
                <p><strong>Numero de Serie Modem: </strong> ${(this.form as any).esn} </p>
                <p><strong>Plan de Servicio: </strong> ${this.$props.terminalSelected.servicesPlan_name}  </p>
                <p><strong>NUEVO Plan de Servicio: </strong> ${(this.form.newPlanServicio as any).name}  </p>
                <br>
                <p>Cuando se ejecute, por favor actualizar el registro de la terminal en la bandeja de solcitudes de BIFROST</p>
                <br>
                <p>Saludos.</p>
                <p>Ordering</p>
            </div>
        </body>
        </html>`

        let subj = `SOLICITUD DE CAMBIO DE PLAN - CLIENTE ${this.form.user_vno}`

        let data = {
          to: ["yeltsinrhp.93@gmail.com"],
          cc: ["yeltsinrhp.93@hotmail.com"],
          subject: subj,
          message: msj
        }


        store.dispatch("ENVIAR_EMAILS", data).then((response) => {
          store.dispatch("showToast", {
            message: `Se envio un mensaje a los responsables`,
            type: "success",
          });
        })

        this.$emit("close");
      }).catch((error) => {
        store.dispatch("showToast", {
          message: String(error.response.data.message[0]),
          type: "error",
        });
        this.$emit("close");
      });
    },
  },
});
</script>

<style lang="scss" scoped>
/* Estilos para el grupo de botones */
.button-group {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
  /* Espacio entre botones */
  flex-wrap: wrap;
  /* Para que los botones se envuelvan si no hay suficiente espacio */
}

/* Estilos para los campos de entrada */
.p-inputtext {
  width: 100%;
  margin-bottom: 1rem;
  color: black;
  /* Color de texto negro */
}

/* Estilo específico para las etiquetas con texto blanco */
.white-text {
  color: white !important;
  /* Color blanco para las etiquetas */
}

/* Estilos para fondos de color azul */
.blue-background {
  background-color: white !important;
  /* Fondo blanco para los selects */
}

.header-content {
  position: relative;
  text-align: center;
  margin-top: -2rem;
  margin-bottom: 2rem;
}

.close-icon {
  position: absolute;
  top: 0;
  right: -1rem;
  cursor: pointer;
}

.header-title {
  margin-top: 2rem;
  margin-bottom: 0rem;
  color: white;
  font-weight: 100;
}
</style>
