/*eslint-disable */
import axios from 'axios'
import { getToken } from '@/utils/auth'
import store from '../store'
// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_RUTA_API_FLASK, // api 的 base_url
    timeout: 120000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // Do something before request is sent
        if (!(config.data instanceof FormData)) {
            config.headers['Content-type'] = 'application/json'
        }
        // config.headers['Access-Control-Allow-Origin'] = '*';
        config.headers['Authorization'] = getToken()
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
// response interceptor
service.interceptors.response.use(
    response => response,
    error => {
        console.log(error);
        store.dispatch('showToast', {
            message: error.response.data.message,
            type: "error"
        })
        return Promise.reject(error)
    }
)

export default service
