import request from '@/utils/grafana-graphics'
const obj = {
  
  getMonitoringUrlPrincipal(values: any) {
    return request({
      url: '/create-snapshot/graphics/monitoreoall',
      method: 'post',
      data: values
    })
  },

  getMonitoringUrlPrincipalTerminal(values: any) {
    return request({
      url: '/create-snapshot/graphics/monitoreoperterminal',
      method: 'post',
      data: values
    })
  },

  getMonitoringUrl(values: any) {
    return request({
      url: '/create-snapshot/graphics/graphterminal360',
      method: 'post',
      data: values
    })
  },

}
export default obj