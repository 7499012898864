import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/eliminar-50.png'


const _hoisted_1 = { class: "header-content" }
const _hoisted_2 = { style: {"text-align":"center","margin-top":"1rem","margin-bottom":"3rem","color":"white","font-weight":"100"} }
const _hoisted_3 = { class: "poppup-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
          style: {"cursor":"pointer","margin-top":"-1rem"},
          width: "25",
          height: "25",
          src: _imports_0,
          alt: "delete-sign--v1"
        }),
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('message.planta.b01')), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.planta.b05')) + "||| " + _toDisplayString(_ctx.terminalSelected.terminals_nickname) + " " + _toDisplayString(_ctx.$t('message.planta.b02')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.terminalSelected.kitSerialNumber) + " " + _toDisplayString(_ctx.$t('message.planta.b03')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.planta.b06')) + " " + _toDisplayString(_ctx.terminalSelected.terminals_nickname) + " " + _toDisplayString(_ctx.$t('message.planta.b04')), 1)
      ])
    ]),
    _: 1
  }))
}