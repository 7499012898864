import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/eliminar-50.png'


const _hoisted_1 = { class: "header-content" }
const _hoisted_2 = { style: {"text-align":"center","margin-top":"1rem","margin-bottom":"3rem","color":"white","font-weight":"100"} }
const _hoisted_3 = { class: "p-col-6" }
const _hoisted_4 = { class: "p-field" }
const _hoisted_5 = {
  for: "suscriptor",
  class: "white-text"
}
const _hoisted_6 = { class: "p-col-6" }
const _hoisted_7 = { class: "p-field" }
const _hoisted_8 = {
  for: "address",
  class: "white-text"
}
const _hoisted_9 = { class: "p-col-6" }
const _hoisted_10 = { class: "p-field" }
const _hoisted_11 = {
  for: "latitud",
  class: "white-text"
}
const _hoisted_12 = { class: "p-col-6" }
const _hoisted_13 = { class: "p-field" }
const _hoisted_14 = {
  for: "longitud",
  class: "white-text"
}
const _hoisted_15 = { class: "p-col-6" }
const _hoisted_16 = { class: "p-field" }
const _hoisted_17 = {
  for: "siteId",
  class: "white-text"
}
const _hoisted_18 = { class: "p-col-6" }
const _hoisted_19 = { class: "p-field" }
const _hoisted_20 = {
  for: "numeroSerie",
  class: "white-text"
}
const _hoisted_21 = { class: "p-col-6" }
const _hoisted_22 = { class: "p-field" }
const _hoisted_23 = {
  for: "modelType",
  class: "white-text"
}
const _hoisted_24 = { class: "p-col-6" }
const _hoisted_25 = { class: "p-field" }
const _hoisted_26 = {
  for: "planServicio",
  class: "white-text"
}
const _hoisted_27 = { class: "p-col-12 p-md-6 button-group" }
const _hoisted_28 = {
  type: "submit",
  class: "p-button p-button-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_PopupRequestHigh = _resolveComponent("PopupRequestHigh")!

  return (_openBlock(), _createBlock(_component_PopupRequestHigh, { onClose: _ctx.closePopup }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
          style: {"cursor":"pointer","margin-top":"-1rem"},
          width: "25",
          height: "25",
          src: _imports_0,
          alt: "delete-sign--v1"
        }),
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('message.planta.f32')), 1)
      ]),
      _createElementVNode("form", {
        onSubmit: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
        class: "p-grid"
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('message.planta.c3')), 1),
            _createVNode(_component_v_select, {
              modelValue: _ctx.form.suscriptor,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.suscriptor) = $event)),
              options: _ctx.subscriptores,
              label: "name",
              placeholder: _ctx.$t('message.planta.c3'),
              class: "custom-select"
            }, null, 8, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('message.planta.c11')), 1),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.address) = $event)),
              id: "address",
              class: "p-inputtext"
            }, null, 512), [
              [_vModelText, _ctx.form.address]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('message.planta.c13')), 1),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.latitud) = $event)),
              id: "latitud",
              class: "p-inputtext"
            }, null, 512), [
              [_vModelText, _ctx.form.latitud]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('message.planta.c12')), 1),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.longitud) = $event)),
              id: "longitud",
              class: "p-inputtext"
            }, null, 512), [
              [_vModelText, _ctx.form.longitud]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('message.planta.c2')), 1),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.siteId) = $event)),
              id: "siteId",
              class: "p-inputtext"
            }, null, 512), [
              [_vModelText, _ctx.form.siteId]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t('message.planta.c6')), 1),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.numeroSerie) = $event)),
              id: "numeroSerie",
              class: "p-inputtext"
            }, null, 512), [
              [_vModelText, _ctx.form.numeroSerie]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t('message.planta.c4')), 1),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.modelType) = $event)),
              id: "modelType",
              class: "p-inputtext"
            }, null, 512), [
              [_vModelText, _ctx.form.modelType]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("label", _hoisted_26, _toDisplayString(_ctx.$t('message.planta.c10')), 1),
            _createVNode(_component_v_select, {
              modelValue: _ctx.form.planServicio,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.planServicio) = $event)),
              options: _ctx.planesServicio,
              label: "name",
              placeholder: _ctx.$t('message.planta.f13'),
              class: "custom-select"
            }, null, 8, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("button", _hoisted_28, _toDisplayString(_ctx.$t('message.planta.f33')), 1),
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
            class: "p-button p-button-secondary"
          }, _toDisplayString(_ctx.$t('message.planta.f34')), 1)
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["onClose"]))
}