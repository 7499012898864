import request from '@/utils/mail'
const obj = {
  sendmail(values:any) {
    return request({
      url: '/',
      method: 'post',
      data: values
    })
  },
}
export default obj