<template>
  <div class="popupFech">
      <div class="popupFech-content">
          <slot></slot>
      </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PopupFech',
});
</script>

<style scoped>
.popupFech {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(8, 8, 8, 0.933);
display: flex;
justify-content: center;
align-items: center;
}

.popupFech-content {
/* background-color: white; */
padding: 40px; /* Ajusta el padding según tus necesidades para agregar más espacio */
width: 100%;
max-width: 450px;
max-height: 100%;
background-image: url('../../assets/Rectangle\ 40\ popup.png'); 
background-size: cover;
opacity: 1; /* Opacidad de la imagen de fondo */
border-radius: 10px;
}

</style>
