import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/Galaxy.mp4'


const _hoisted_1 = {
  src: _imports_0,
  autoplay: "",
  muted: "",
  loop: "",
  playsinline: "",
  class: "video_bg large",
  id: "videoID"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("video", _hoisted_1))
}