<template>
    <Popup>
      <div class="header-content">
      <img
        @click="closePopup"
        style="cursor: pointer; margin-top: -1rem"
        width="25"
        height="25"
        src="@/assets/eliminar-50.png"
        alt="delete-sign--v1"
      />
      <h1
        style="
          text-align: center;
          margin-top: 1rem;
          margin-bottom: 3rem;
          color: white;
          font-weight: 100;
        ">
          {{$t('message.planta.f18')}} {{ terminalSelected.terminals_nickname }}
        </h1>
     
      </div>

      <div>
        <form class="popup-form" @submit.prevent="verificarAddress()">
            <div  class="group">
                <input disabled v-model="terminalSelected.directions_formattedAddress" class="custom-input" type="text" :placeholder="$t('message.planta.c11')"
                    style="padding-left: 0.5rem" />
            </div>
            <div class="group">
                <input disabled v-model="terminalSelected.directions_latitude" class="custom-input" type="text" :placeholder="$t('message.planta.f16')"
                    style="padding-left: 0.5rem" />
            </div>
            <div class="group">
                <input disabled v-model="terminalSelected.directions_longitude" class="custom-input" type="text" :placeholder="$t('message.planta.f16')"
                    style="padding-left: 0.5rem" />
            </div>
          <div class="group">
            <input required v-model="newService.direction" class="custom-input" type="text" :placeholder="$t('message.planta.f17')"
              style="padding-left: 0.5rem" />
          </div>
   
          <div class="btn-content">
            <button class="btn-submit-address" type="submit">
              {{
                coordenate.length > 0
                ? $t('message.planta.f3')
                : $t('message.planta.f4')
              }}
            </button>
          </div>
        </form>
      </div>
  
      <div
        style="margin: 20px 0px"
        v-if="validAddress"
      >
        <Map :height="200" :width="320"></Map>
      </div>
  
      <form v-if="validAddress" class="popup-form"   @submit.prevent="changeAddress()">
        <div v-if="newData.params" class="group">
                <input disabled v-model="newData.params.regionCode" class="custom-input" type="text" :placeholder="$t('message.planta.c11')"
                    style="padding-left: 0.5rem" />
            </div>
            <div v-if="newData.params" class="group">
                <input disabled v-model="newData.params.latitude" class="custom-input" type="text" :placeholder="$t('message.planta.f16')"
                    style="padding-left: 0.5rem" />
            </div>
            <div v-if="newData.params" class="group">
                <input disabled v-model="newData.params.longitude" class="custom-input" type="text" :placeholder="$t('message.planta.f16')"
                    style="padding-left: 0.5rem" />
            </div>
        <div class="btn-content">
          <button
            :disabled="process"
            class="btn-submit"
            type="submit"
            style="margin-left: 1rem"
          >
            <div
              style="
                display: flex;
                justify-content: space-evenly;
                width: 90%;
                margin: auto;
                align-items: center;
              ">
              <div>{{$t('message.planta.f18')}}</div>
              <div>
                <img src="@/assets/Vector-servicio.png" alt="img-vector" />
              </div>
            </div>
          </button>
        </div>
      </form>
    </Popup>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  import Popup from "../../../components/planta-conte/Popup.vue";
  import store from "../../../store";
  import "@/assets/sass/StylesPlanta.scss";
  import { mapState, mapGetters } from "vuex";
  import Map from "../../../components/map/index.vue";
  import vSelect from "vue-select";
  
  export default defineComponent({
    name: "newService",
    props: ["terminalSelected"],
    emits: ["close"],
    components: {
      Popup,
      Map,
      vSelect,
    },
    computed: {
      ...mapState({
        coordenate: (state: any) => state.AUTH.coordenateData,
        user: (state: any) => state.AUTH.user,
        token: (state: any) => state.AUTH.token
      }),
      ...mapGetters(["permissions"]),
  
    },
    data() {
      return {
        validAddress:false,
        process:false,
        newService: {
            direction: "",
            addressLines: "",
            locality: "",
            administrativeArea: "", // mayuscula
            administrativeAreaCode: "", // mayuscula
            region: "",
            regionCode: "", // mayuscula
            postalCode: "",
            latitude: "",
            longitude: "",
            formattedAddress: "",
            productReferenceId:"",
        },
        newData:{
        },
      };
    },
    mounted() {
    },
    methods: {
      closePopup() {
        this.$emit("close");
      },
      verificarAddressOld() {
        store
          .dispatch("GET_COORDENADE", this.newService.direction)
          .then((response) => {
            if (response.data.features.length > 0) {
              var dataValid={
                endpointId:9,
                params:{
                  accountNumber:this.user.accountNumber,
                  addressLines:response.data.features[0].properties.formatted,
                  locality: response.data.features[0].properties.state,
                  administrativeArea: String(response.data.features[0].properties.state_code).toUpperCase()==="UNDEFINED" ? "" :String(response.data.features[0].properties.state_code).toUpperCase(), // mayuscula
                  administrativeAreaCode:String(response.data.features[0].properties.state_code).toUpperCase()==="UNDEFINED" ? "" :String(response.data.features[0].properties.state_code).toUpperCase(), // mayuscula
                  region: response.data.features[0].properties.country,
                  regionCode: String(response.data.features[0].properties.country_code).toUpperCase(), // mayuscula
                  latitude: response.data.features[0].properties.lat,
                  longitude: response.data.features[0].properties.lon,
                  formattedAddress: response.data.features[0].properties.formatted,
                  postalCode: response.data.features[0].properties.postcode,
                  directions_addressReferenceId:this.terminalSelected.directions_addressReferenceId,
                  directions_id:this.terminalSelected.directions_id,

                }
              }
              this.newData=dataValid;
              this.validAddress=true;
            } else {
              store.dispatch("showToast", {
                message: "NO ENCONTRO DIRECCIÓN",
                type: "error",
              });
            }
          })
          .catch((error) => {
            console.log("error", error);
            
          });
      },
      verificarAddress() {
          var dataValid = {
            endpointId: 12,
            principalId: this.user.principalId,
            platform: "ses",
            roleId: this.user.roleId,
            userId: this.user.id,
            orgId: this.user.orgId,
            xparams: {
              direction: this.newService.direction
            }
         }
         store.dispatch("SERVICELINEUSERCONFIG", dataValid).then((response2) => {
               if (response2.data.isValid) {
               this.validAddress = true;
              if (response2.data.data.results.length>0) {
         
                var dataValue= response2.data.data.results[0];
                var country = {
                  long_name:null,
                  short_name:null
                };
                var postalCodeRef = {
                  long_name:null,
                  short_name:null
                };
                var localCodeRef = {
                  long_name:null,
                  short_name:null
                };
                dataValue.address_components.forEach((component:any)=> {
                  component.types.forEach((evt:any) => {
                      if (evt==="country") {
                          country = component;
                      }
                      if (evt==="postal_code") {
                        postalCodeRef = component;
                      }
                      if (evt==="locality") {
                        localCodeRef = component;
                      }
                    })
                });
                var dataValid = {
                  endpointId:9,
                    principalId: this.user.principalId,
	                  platform: "ses",
	                  roleId: this.user.roleId,
	                  userId: this.user.id,
	                  orgId: this.user.orgId,
                    params: {
                       accountNumber: this.user.accountNumber,
                       addressLines: response2.data.data.results[0].formatted_address,
                       locality: localCodeRef ? localCodeRef.long_name : "",
                       administrativeArea: country ? country.short_name : "", // mayuscula
                       administrativeAreaCode: country ? country.short_name : "", // mayuscula
                       region: country ? country.long_name : "",
                       regionCode: country ? country.short_name : "", // mayuscula
                       latitude: response2.data.data.results[0].geometry.location.lat,
                       longitude: response2.data.data.results[0].geometry.location.lng,
                       formattedAddress: response2.data.data.results[0].formatted_address,
                       postalCode: postalCodeRef ? postalCodeRef.long_name : "",
                       directions_addressReferenceId:this.terminalSelected.directions_addressReferenceId,
                       directions_id:this.terminalSelected.directions_id,
                    }
                  }
                  store.commit("SET_COORDENATE", dataValid);
                } else {
                  store.dispatch("showToast", {
                    message: "NO ENCONTRO DIRECCIÓN",
                    type: "error",
                  });
                }
                store.dispatch("showToast", {
                  message: "VERIFICADO",
                  type: "success",
                });
               } else {
                // this.validAddress = false;
                 store.dispatch("showToast", {
                   message: response2.data.message[0],
                   type: "error",
                 });
               }
              console.log(response2);
            }).catch((error) => {
              this.validAddress = false;
              store.dispatch("showToast", {
                message: String(error.response.data.message[0]),
                type: "error",
              });
            });
         
      
    },
      changeAddress(){
        if (this.permissions.some( (evt:any) => evt.id===40)) {
        store.dispatch("GET_VALID_COORDENADE",this.coordenate).then((response2) => {  
              if (response2.data.isValid) {
                    store.dispatch("showToast", {
                    message: "Cambio realizado exitosamente",
                    type: "success",
                  });
                  this.$emit("close"); 
              }else{
                this.validAddress=false;
                store.dispatch("showToast", {
                  message: response2.data.message[0],
                  type: "error",
                });
              }
              console.log(response2);
            }).catch((error) => {
              this.validAddress=false;
              this.$emit("close");
              store.dispatch("showToast", {
              message: String(error.response.data.message[0]),
              type: "error",
            });
            });
         }else{
         store.dispatch("showToast", {
             message: "NO TIENES PERMISOS PARA ESTA ACCION",
             type: "error",
           });
         }
    }
    },
  });
  </script>
  <style lang="scss">
  .dropdown-content {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
    margin-top: 0px;
  }
  
  .dropdown-content:last-of-type {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
    margin-top: -225px;
    margin-left: 45px;
  }
  
  .dropdown-content:hover {
    position: fixed;
    display: none;
    width: 160px;
    height: 225px;
    background: #0066ff;
    border-radius: 10px;
  }
  
  .dropdown-content a:hover {
    background-color: #0079ff;
  }
  
  .btn-submit-address {
    height: 32px;
    width: 220px;
    border: none;
    border-radius: 9px;
    color: #ffffff;
    font-weight: bold;
    background-color: #0066ff;
  }
  
  .btn-submit-mapa {
    height: 32px;
    width: 220px;
    border: none;
    border-radius: 9px;
    color: #ffffff;
    font-weight: bold;
    background-color: #0066ff;
  }
  
  .custom-select .vs__dropdown-toggle {
    background-color: white;
  }
  
  .custom-input {
    background-color: white;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    width: 95%;
    cursor: pointer;
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  </style>
  