import request from "@/utils/aplication-flask";
const obj = {
  createRequest(values: any) {
    return request({
      url: "/create_request",
      method: "post",
      data: values,
    });
  },
  updateRequestStatus(values: any) {
    return request({
      url: "/update_request_status",
      method: "post",
      data: values,
    });
  },
  updatePlantaAll(values: any) {
    return request({
      url: "/update_planta_all",
      method: "post",
      data: values,
      headers: {
        'Content-Type': 'multipart/form-data' // Especificar el Content-Type aquí
      }
    });
  },
};
export default obj;
