<template>
  <PopupComi style="z-index: 5" v-if="showPopupDrowComisionamiento">
    <div class="header-close">
      <img style="cursor: pointer" width="25" height="25" src="@/assets/eliminar-50.png" alt="delete-sign--v1"
        @click="closePopup" />
    </div>
    <div class="header-content">
      <h2 style="color: white; text-align: center">
        {{ $t("message.planta.f9") }}
      </h2>
    </div>
    <form class="popup-form" action="" @submit="confirmSubmitSoporte">
      <div class="file-upload-container">
        <input type="file" id="file-upload" class="file-upload-input" ref="fileInput" />

        <label for="file-upload" class="file-upload-label">{{
          $t("message.planta.f10")
        }}</label>
        <div class="btn-content">
          <button @click.prevent="cargarArchivo()" class="btn-submit" type="submit" id="btn-cerrar-popup2"
            style="margin-top: 1rem; margin-left: 1rem">
            <strong>{{ $t("message.planta.f11") }}</strong>
          </button>
        </div>

        <div>
          <DataTable class="table-upload-file" style="width: 400px">
            <Column field="code" header="Code"></Column>
            <Column field="name" header="Name"></Column>
            <Column field="category" header="Category"></Column>
            <Column field="quantity" header="Quantity"></Column>
          </DataTable>
        </div>
      </div>
    </form>
  </PopupComi>

  <PopupDrop style="z-index: 5" v-if="showPopupDrow && terminalSelected">
    <div class="header-close">
      <img style="cursor: pointer; margin-top: -1rem" width="25" height="25" src="@/assets/eliminar-50.png"
        alt="delete-sign--v1" @click="closePopup" />
    </div>
    <div class="header-content">
      <h2 style="color: white">
        {{ $t("message.planta.f12") }}
        {{ String(terminalSelected.terminals_nickname).toUpperCase() }}
      </h2>
    </div>
    <form class="popup-form" action="">
      <div class="group">
        <v-select v-model="newService.planservice" :options="changeserviceplan" label="name"
          :placeholder="$t('message.planta.f13')" class="custom-select" />
      </div>

      <div class="btn-content">
        <button @click.prevent="cambioPlan(terminalSelected)" class="btn-submit" type="submit" id="btn-cerrar-popup2"
          style="margin-top: 1rem; margin-left: 1rem">
          <strong>{{ $t("message.planta.f14") }}</strong>
        </button>
      </div>
    </form>
  </PopupDrop>

  <PopupDrop style="z-index: 5" v-if="showPopupDrowSus && terminalSelected">
    <div class="header-close">
      <img style="cursor: pointer; margin-top: -1rem" width="25" height="25" src="@/assets/eliminar-50.png"
        alt="delete-sign--v1" @click="closePopup" />
    </div>
    <div class="header-content">
      <h1 style="color: white">{{ $t("message.planta.f28") }}</h1>
    </div>
    <form class="popup-form">
      <div class="group">
        <h3 style="color: white; line-height: 25px">
          {{ $t("message.planta.f29") }}
          {{ terminalSelected.terminals_nickname.toUpperCase() }} ?
        </h3>
      </div>

      <div class="btn-content">
        <button @click.prevent="suspender(terminalSelected)" class="btn-submit" type="submit" id="btn-cerrar-popup2"
          style="margin-left: 1rem">
          <strong>{{ $t("message.planta.f30") }}</strong>
        </button>
      </div>
    </form>
  </PopupDrop>

  <PopupDrop style="z-index: 5" v-if="showPopupDrowBaja && terminalSelected">
    <div class="header-close">
      <img style="cursor: pointer; margin-top: -1rem" width="25" height="25" src="@/assets/eliminar-50.png"
        alt="delete-sign--v1" @click="closePopup" />
    </div>
    <div class="header-content">
      <h1 style="color: white">{{ $t("message.planta.f36") }}</h1>
    </div>
    <form class="popup-form" action="" @submit="confirmSubmitBaja">
      <!-- <div class="group">
        <h3 style="color: white; line-height: 25px">
          {{ $t("message.planta.f20") }}
        </h3>
      </div> -->

      <div class="group">
        <h3 style="color: white; line-height: 25px">
          SiteID: {{ terminalSelected.siteId }}
        </h3>
      </div>
      <div class="group">
        <h3 style="color: white; line-height: 25px">
          Modem: {{ terminalSelected.esn }}
        </h3>
      </div>
      <div class="group">
        <p style="color: #f02647; line-height: 23px; text-align: justify;font-size: 1.4rem;">
          {{ $t("message.planta.f35") }}
        </p>
      </div>

      <div class="btn-content" style="display: flex; justify-content: space-evenly;">
        <button @click.prevent="bajaTotal(terminalSelected)" class="btn-baja" type="submit" id="btn-cerrar-popup2">
          <strong>{{ $t("message.planta.f33") }}</strong>
        </button>
        <button class="btn-baja" @click="closePopup" type="button">
          <strong>{{ $t("message.planta.f34") }}</strong>
        </button>
      </div>
    </form>
  </PopupDrop>

  <Popup style="z-index: 5" v-if="showPopupDrowSoporte && terminalSelected">
    <div class="header-close">
      <img style="cursor: pointer; margin-top: -1rem" width="25" height="25" src="@/assets/eliminar-50.png"
        alt="delete-sign--v1" @click="closePopup" />
    </div>
    <div class="header-content">
      <h2 style="color: white">
        {{ $t("message.planta.f22") }} {{ terminalSelected.nickname }}
      </h2>
    </div>

    <form class="popup-form" @submit.prevent="generarTicket(ticketSoporte, terminalSelected)">
      <div class="group">
        <v-select v-model="ticketSoporte.typeContact" :options="problemtypes.dataTypeContactId" label="name"
          placeholder="Selecciona el Tipo de Contacto" class="custom-select" />
      </div>

      <div class="group">
        <input v-model="ticketSoporte.dataTypeContact" class="custom-input" type="text"
          placeholder="Ingresa el cliente" />
      </div>

      <div class="group">
        <v-select v-model="ticketSoporte.mediaComunication" :options="problemtypes.dataMediaComunicationId" label="name"
          placeholder="Selecciona la Media Comunicacion" class="custom-select" />
      </div>

      <div class="group">
        <input v-model="ticketSoporte.dataComunication" class="custom-input" type="text"
          placeholder="Ingresa el Medio de Comunicacion" />
      </div>

      <div class="group">
        <v-select v-model="ticketSoporte.request" :options="problemtypes.dataPinProblem" label="name"
          placeholder="Selecciona el tipo de solicitud" class="custom-select" />
      </div>

      <div class="group">
        <textarea v-model="ticketSoporte.descripcion" class="custom-input" type=""
          :placeholder="$t('message.planta.f26')" style="padding-left: 0.5rem; width: 318px; height: 87px" name="" id=""
          cols="30" rows="10"></textarea>
      </div>

      <div class="btn-content">
        <button class="btn-submit" type="submit" id="btn-cerrar-popup2" style="margin-top: 1rem; margin-left: 1rem">
          <strong>{{ $t("message.planta.f27") }}</strong>
        </button>
      </div>
    </form>
  </Popup>

  <PopupFecha360 style="z-index: 5" v-if="showPopupFecha">
    <div class="header-close">
      <img style="cursor: pointer; margin-top: -1rem" width="25" height="25" src="@/assets/eliminar-50.png"
        alt="delete-sign--v1" @click="closePopup" />
    </div>
    <div class="header-content">
      <h2 style="color: white">
        {{ $t("message.planta.f31") }}
      </h2>
    </div>

    <div>
      <div class="card flex justify-content-center">
        <Calendar v-model="dates" selectionMode="range" :manualInput="false" @dateClick="handleDateClick" />
      </div>

      <div class="card flex justify-content-center gap-3" style="margin-top: 2rem">
        <a @click="gotografanawithDate(terminalSelected)" rel="noopener noreferrer"
          class="p-button font-bold">Acceder</a>
      </div>
    </div>
  </PopupFecha360>

  <newPlant style="z-index: 5" v-if="showPopup" @close="closePopup()"></newPlant>

  <ChangeAdrees style="z-index: 5" v-if="showPopupAddress" :terminalSelected="terminalSelected" @close="closePopup()">
  </ChangeAdrees>

  <BajaValidation style="z-index: 5" v-if="showPopupBajaValidation" :terminalSelected="terminalSelected"
    @close="closePopup()">
  </BajaValidation>

  <requestHigh style="z-index: 5" v-if="showPopupDrowAlta" @close="closePopup()">
  </requestHigh>

  <changePlan style="z-index: 5" v-if="showPopupChangePlan" :terminalSelected="terminalSelected" @close="closePopup()">
  </changePlan>

  <reactivate style="z-index: 5" v-if="showPopupReactivate" :terminalSelected="terminalSelected" @close="closePopup()">
  </reactivate>

  <!-- <reactivate/> -->

  <ChangeModem style="z-index: 5" v-if="showPopupDrowChangeModem" :terminalSelected="terminalSelected"
    @close="closePopup()">
  </changeModem>

  <PopupDrop style="z-index: 5" v-if="showPopupDrowSuspend && terminalSelected">
    <div class="header-close">
      <img style="cursor: pointer; margin-top: -1rem" width="25" height="25" src="@/assets/eliminar-50.png"
        alt="delete-sign--v1" @click="closePopup" />
    </div>
    <div class="header-content">
      <h1 style="color: white">{{ $t("message.planta.f39") }}</h1>
    </div>
    <form class="popup-form" action="">
      <!-- <div class="group">
        <h3 style="color: white; line-height: 25px">
          {{ $t("message.planta.f20") }}
        </h3>
      </div> -->

      <div class="group">
        <h3 style="color: white; line-height: 25px">
          SiteID: {{ terminalSelected.siteId }}
        </h3>
      </div>
      <div class="group">
        <h3 style="color: white; line-height: 25px">
          Modem: {{ terminalSelected.esn }}
        </h3>
      </div>
      <div class="group">
        <p style="color: #5ad934; line-height: 23px; text-align: justify;font-size: 1.4rem; text-align: center;">
          {{ $t("message.planta.f40") }}
        </p>
      </div>

      <div class="btn-content" style="display: flex; justify-content: space-evenly;">
        <button @click.prevent="suspendRequest(terminalSelected)" class="btn-baja" type="submit" id="btn-cerrar-popup2">
          <strong>{{ $t("message.planta.f33") }}</strong>
        </button>
        <button class="btn-baja" @click="closePopup" type="button">
          <strong>{{ $t("message.planta.f34") }}</strong>
        </button>
      </div>
    </form>
  </PopupDrop>


  <div style="z-index: 1" class="table-content">
    <div class="buscar-content">
      <div v-if="permissions.some((evt: any) => evt.id === 115)" style="margin-left: 0px !important; cursor: pointer"
        class="nuevo-servicio" @click="showPopup = true">
        <div>{{ $t("message.home.btn2") }}</div>
        <div>
          <img src="@/assets/Vector-servicio.png" alt="img-vector" />
        </div>
      </div>

      <!-- <div v-if="permissions.some((evt: any) => evt.id === 113)" style="margin-left: 0px !important; cursor: pointer"
        class="nuevo-servicio" @click="showPopupDrowAlta = true">
        <div>{{ $t("message.planta.op8") }}</div>
        <div>
          <img src="@/assets/Vector-servicio.png" alt="img-vector" />
        </div>
      </div> -->

      <div v-if="permissions.some((evt: any) => evt.id === 114)">
        <label for="file-upload" class="custom-file-upload">
          <input id="file-upload" type="file" ref="fileInput" accept=".csv" @change="confirmUpload">
          {{ $t("message.planta.f38") }}
        </label>
      </div>

      <div style="margin-right: 0px !important" class="buscar-servicio">
        <input v-model="query" class="buscador" type="text" :placeholder="$t('message.planta.search')" name="" id=""
          style="font-size: 16px" />
        <button class="circle">
          <img src="@/assets/Vector-search.png" alt="img-search" />
        </button>
        <div class="btn-content">
          <button @click="exportCSV($event)">{{ $t("message.export") }}</button>
        </div>

      </div>
    </div>
    <DataTable :value="filteredTerminals" paginator :rows="8" ref="dt" :rowsPerPageOptions="[8]" stripedRows
      tableStyle="min-width: 1300px">
      <!-- <template #header>
        <div style="text-align: left">
           
        </div>
    </template> -->
      <Column class="title-c" v-for="(column, index) in myColumnTablet" :key="'column-' + index" :sortable="column.sort"
        :field="column.value" :header="$t(column.ts)" :style="'color:#000000!important;text-align:center;'">
        <template v-if="column.childrem && column.name === 'Action'" #body="{ data }">
          <button class="menu-button" @click.prevent="
            data.show === true ? (data.show = false) : (data.show = true)
            ">
            <img src="@/assets/menu.png" alt="burguer-menu" />
          </button>
          <form @mouseleave="data.show = false">
            <Menu id="overlay_menu" ref="menu" style="position: absolute" :popup="!data.show">
              <template #start #body="{ data }">

                <button v-if="permissions.some((evt: any) => evt.id === 12)" @click="
                  showPopupDrowComisionamiento = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.op1")
                      }}</span>
                    </div>
                  </div>
                </button>

                <!-- <button @click="
                  terminalSelect(data);
                showPopupChangePlan = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.f37")
                      }}</span>
                    </div>
                  </div>
                </button> -->

                <!-- <button v-if="permissions.some((evt: any) => evt.id === 117)" @click="
                  terminalSelect(data);
                showPopupDrow = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.op2")
                      }}</span>
                    </div>
                  </div>
                </button> -->

                <!-- <button v-if="permissions.some((evt: any) => evt.id === 39)" @click="
                  terminalSelect(data);
                showPopupAddress = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.op3")
                      }}</span>
                    </div>
                  </div>
                </button> -->

                <!-- <button v-if="permissions.some((evt: any) => evt.id === 14)" @click="
                  terminalSelect(data);
                showPopupDrowSus = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/note-edit.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.op4")
                      }}</span>
                    </div>
                  </div>
                </button> -->

                <!-- <button v-if="permissions.some((evt: any) => evt.id === 116)" @click="
                  terminalSelect(data);
                showPopupDrowBaja = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/trash.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.f36")
                      }}</span>
                    </div>
                  </div>
                </button> -->

                <!-- <button v-if="permissions.some((evt: any) => evt.id === 55)" @click="
                  showPopupDrowSoporte = true;
                terminalSelect(data);
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/handshake.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.op6")
                      }}</span>
                    </div>
                  </div>
                </button> -->

                <button v-if="permissions.some((evt: any) => evt.id === 42)" @click.prevent="gotografana(data)"
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/signal.svg" alt="" />
                      <span class="font-bold"> 360 </span>
                    </div>
                  </div>
                </button>

                <button v-if="permissions.some((evt: any) => evt.id === 42)" @click="
                  showPopupFecha = true;
                terminalSelect(data);
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/signal.svg" alt="" />
                      <span class="font-bold"> CUSTOM 360 </span>
                    </div>
                  </div>
                </button>

                <!-- <div v-if="data.technicalStatus != 'suspend'">
                  <button @click="
                    terminalSelect(data);
                  showPopupDrowSuspend = true;
                  data.show = false;
                  " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                    <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                      shape="circle" />
                    <div class="flex flex-column align">
                      <div class="flexing-menu">
                        <img src="@/assets/trash.svg" alt="" />
                        <span class="font-bold">{{
                          $t("message.planta.f39")
                        }}</span>
                      </div>
                    </div>
                  </button>
                </div>

                <div v-else>
                  <button @click="
                    terminalSelect(data);
                    showPopupReactivate = true;
                    data.show = false;
                    " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                      <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                        shape="circle" />
                      <div class="flex flex-column align">
                        <div class="flexing-menu">
                          <img src="@/assets/checkbox-circle.svg" alt="" />
                          <span class="font-bold">{{
                            $t("message.planta.f42")
                          }}</span>
                        </div>
                      </div>
                  </button>

                </div> -->

                <!-- <button @click="
                  terminalSelect(data);
                showPopupDrowChangeModem = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/trash.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.f41")
                      }}</span>
                    </div>
                  </div>
                </button> -->

                <!-- <button v-if="permissions.some((evt: any) => evt.id === 42)" @click.prevent="goto360(data)"
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/signal.svg" alt="" />
                      <span class="font-bold">360</span>
                    </div>
                  </div>
                </button> -->
              </template>
            </Menu>
          </form>
        </template>
        <template v-else-if="column.childrem && column.name === 'Estado'" #body="{ data }">
          <button :class="data.statusTerminal === 'Online' ? 'btn-active' : 'btn-inactive'
            ">
            {{ data.statusTerminal === "Online" ? "Online" : "Offline" }}
          </button>
        </template>
        <template v-else-if="column.childrem && column.name === 'Ultima Conexión'" #body="{ data }">
          {{ String(data.billingConsumption_lastDailyDate).substring(0, 10) }}
        </template>
        <template v-else-if="column.childrem && column.name === 'Dirección'" #body="{ data }">
          {{ String(data.addressRoute).substring(0, 40) }}
        </template>
        <template v-else-if="column.childrem && column.name === 'RFS'" #body="{ data }">
          {{ String(data.activationDate).substring(0, 10) }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Popup from "../../components/planta-conte/Popup.vue";
import PopupDrop from "../../components/planta-conte/PopupDropDown.vue";
import PopupComi from "../../components/planta-conte/PopupComisionamiento.vue";
import PopupFecha360 from "@/components/planta-conte/PopupFecha360.vue";

import ChangeAdrees from "./poppup/updateAddress.vue";
import ChangeModem from "./poppup/changeModem.vue";
import requestHigh from "./poppup/requestHigh.vue";
import changePlan from "./poppup/changePlan.vue";
import reactivate from "./poppup/reactivate.vue";
import BajaValidation from "./poppup/bajaValidation.vue";
import store from "../../store/index";
import { ApiResponsePlantaArray } from "../../interface/planta";
import { mapGetters, mapState } from "vuex";
import newPlant from "./newService.vue";
import vSelect from "vue-select";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Column from "primevue/column";
import Menu from "primevue/menu";
import "primeflex/primeflex.css";
import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/primevue.min.css"; /* Deprecated */

export default defineComponent({
  name: "planta",
  components: {
    Popup,
    PopupDrop,
    newPlant,
    vSelect,
    DataTable,
    Column,
    Menu,
    ChangeAdrees,
    PopupComi,
    Button,
    BajaValidation,
    PopupFecha360,
    Calendar,
    requestHigh,
    changePlan,
    ChangeModem,
    reactivate
  },

  computed: {
    ...mapState({
      coordenate: (state: any) => state.AUTH.coordenateData,
      user: (state: any) => state.AUTH.userDecode,
      token: (state: any) => state.AUTH.token,
    }),
    ...mapGetters(["filteredTerminals", "permissions", "mycolumns"]),
    query: {
      get() {
        return store.state.dataFilter.text;
      },
      set(value: any) {
        store.commit("SET_FILTER_TERMINALS", {
          filter: "text",
          value,
        });
      },
    },
  },

  data() {
    return {
      visible: false,
      showPopup: false,
      showPopupAddress: false,
      showPopupDrow: false,
      showPopupDrowSus: false,
      showPopupDrowBaja: false,
      showPopupDrowSuspend: false,
      showPopupDrowChangeModem: false,
      showPopupDrowSoporte: false,
      showPopupBajaValidation: false,
      showPopupDrowComisionamiento: false,
      showPopupDrowAlta: false,
      showPopupChangePlan: false,
      showPopupReactivate: false,
      showPopupFecha: false,
      newService: {
        siteid: "",
        planservice: "",
        direction: "",
        serialnumber: "",
        suscriptor: "",
      },
      columnTablet: [
        {
          name: "Cliente",
          ts: "message.planta.c1",
          value: "principal_name",
          mode: "ASC",
          sort: true,
          childrem: false,
          width: "5",
          rule: 44,
        },
        {
          name: "Nombre Suscriptor",
          value: "subscribers_name",
          ts: "message.planta.c3",
          mode: "ASC",
          sort: true,
          childrem: false,
          width: "5",
          rule: 45,
        },
        {
          name: "Site ID",
          ts: "message.planta.c2",
          value: "siteId",
          mode: "ASC",
          sort: true,
          childrem: false,
          width: "5",
          rule: 46,
        },
        {
          name: "Serie del modem",
          ts: "message.planta.c4",
          value: "esn",
          mode: "ASC",
          sort: true,
          childrem: true,
          width: "6",
          rule: 47,
        },
        {
          name: "Estado",
          ts: "message.planta.c5",
          value: "statusTerminal",
          mode: "ASC",
          sort: true,
          childrem: true,
          width: "6",
          rule: 48,
        },
        {
          name: "Plan de Servicio",
          ts: "message.planta.c10",
          value: "servicesPlan_name",
          mode: "ASC",
          sort: true,
          childrem: false,
          width: "7.5",
          rule: 49,
        },
        {
          name: "lat",
          ts: "message.planta.c13",
          value: "latitud",
          mode: "ASC",
          sort: true,
          childrem: false,
          width: "5",
          rule: 50,
        },
        {
          name: "Long",
          ts: "message.planta.c12",
          value: "longitud",
          mode: "ASC",
          sort: true,
          childrem: true,
          width: "5",
          rule: 51,
        },
        {
          name: "Dirección",
          ts: "message.planta.c11",
          value: "addressRoute",
          mode: "ASC",
          sort: true,
          childrem: true,
          width: "10",
          rule: 52,
        },
        {
          name: "RFS",
          ts: "RFS",
          value: "activationDate",
          mode: "ASC",
          sort: true,
          childrem: true,
          width: "15",
          rule: 52,
        },
        {
          name: "Action",
          ts: "message.planta.c14",
          value: null,
          mode: "ASC",
          sort: false,
          childrem: true,
          width: "5",
          rule: 52,
        },
      ],
      myColumnTablet: [],
      changeplan: "",
      changeserviceplan: [],
      busqueda: null,
      dataSource: [] as ApiResponsePlantaArray,
      originalDataSource: [] as ApiResponsePlantaArray,
      resultadosBusqueda: [] as ApiResponsePlantaArray,
      terminalSelected: null,
      ticketSoporte: {
        request: "",
        descripcion: "",
        mediaComunication: "",
        dataComunication: "",
        typeContact: "",
        dataTypeContact: "",
      },

      problemtypes: {
        dataMediaComunicationId: [],
        dataPinPriorityId: [],
        dataPinProblem: [],
        dataTypeContactId: [],
      },
      // dates: null
      dates: [] as Date[],
      // problemtypes1: [],
      // buttondisplay: null,
      // icondisplay: null,
      // templatedisplay: null,
    };
  },
  mounted() {
    console.log("PERMISOS", this.permissions)
    this.problemtype();

    // this.problemtypes();

    setTimeout(() => {
      var self = this;
      var mycolumns: (
        | {
          name: string;
          value: string;
          mode: string;
          sort: boolean;
          childrem: boolean;
          width: string;
          rule: number;
        }
        | {
          name: string;
          value: null;
          mode: string;
          sort: boolean;
          childrem: boolean;
          width: string;
          rule: number;
        }
      )[] = [];
      this.columnTablet.forEach((columnT) => {
        // console.log("COLUMN", columnT)
        if (
          this.permissions.some((evt: any) => evt.id === columnT.rule) === true
        ) {
          mycolumns.push(columnT);
        }
      });

      this.mycolu(mycolumns);
      if (this.permissions.some((evt: any) => evt.id === 43)) {
        var data = {
          endpoint: 16,
          type: 2,
        };
        this.serviceline(data);
      }
      // this.serviceplan();
    }, 2000);
  },

  methods: {
    confirmUpload(event: any) {
      const selectedFile = event.target.files[0];
      if (!selectedFile) {
        store.dispatch("showToast", {
          message: "No file selected",
          type: "error",
        });
        return;
      }

      const formData = new FormData();
      formData.append('file', selectedFile);

      store.dispatch("UPDATE_PLANTA_ALL", formData)
        .then((response) => {
          store.dispatch("showToast", {
            message: `Se subió el archivo`,
            type: "success",
          });
          var data = {
            endpoint: 16,
            type: 2,
          };
          this.serviceline(data);
        }).catch((error) => {
          let errorMessage = "Error al subir el archivo";
          if (error.response && error.response.data && error.response.data.message) {
            errorMessage = String(error.response.data.message[0]);
          } else if (error.message) {
            errorMessage = error.message;
          }
          store.dispatch("showToast", {
            message: errorMessage,
            type: "error",
          });
        });
    },

    sendTicket() {
      console.log("ticketSoporte", this.ticketSoporte);
    },
    exportCSV() {
      var data: any = this.$refs.dt;
      if (data) {
        data.exportCSV();
      }

      // dt.value.exportCSV();
    },
    gotografana(terminal: any) {
      var data: { [key: string]: any } = {
        siteId: terminal.siteId,
        activationDate: terminal.activationDate,
        latitud: terminal.latitud,
        longitud: terminal.longitud,
      };
      data["var-principal"] = terminal.principal_id;
      store
        .dispatch("GETMONITORINGURL", data)
        .then((response) => {
          let url = response.data.snapshotUrl;
          const hasQueryParams = url.includes("?");
          const separator = hasQueryParams ? "&" : "?";
          const urlWithKioskParam = url + separator + "kiosk";
          window.open(urlWithKioskParam, "_blank");
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    goto360(terminal: any) {
      var idTerminal = null;
      if (terminal.bifrost2Id) {
        idTerminal = terminal.bifrost2Id;
      } else {
        idTerminal = terminal.id;
      }
      var url = " https://laravel360.bifrost.pe/demo/" + idTerminal;
      window.open(url, "_blank");
    },
    verificarAddress() {
      store
        .dispatch("GET_COORDENADE", this.newService.direction)
        .then((response) => {
          store.dispatch("showToast", {
            message: "VERIFICADO",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    mycolu(data: any) {
      if (data.length > 0) {
        this.myColumnTablet = data;
      } else {
        return [];
      }
    },
    // serviceline(data: any) {
    //   console.log(data);
    //   store
    //     // .dispatch("SERVICELINE", data)
    //     .dispatch("SERVICELINEUSERCONFIG", data)
    //     .then((response) => {
    //       // console.log(this.newService);
    //       store.dispatch("showToast", {
    //         message: "LISTADO DE PLANTA",
    //         type: "success",
    //       });
    //       // this.$router.push("/home");

    //       this.originalDataSource = response.data;
    //       // console.log(response.data)
    //       this.showPopup = false;
    //     })
    //     .catch((error) => {
    //       console.log("error", error);
    //     });
    // },
    serviceline(data: any) {
      console.log(data);
      store
        // .dispatch("SERVICELINE", data)
        .dispatch("SERVICELINEUSERCONFIGV3", data)
        .then((response) => {
          // console.log(this.newService);
          store.dispatch("showToast", {
            message: "LISTADO DE PLANTA",
            type: "success",
          });
          // this.$router.push("/home");

          this.originalDataSource = response.data.data;
          console.log("SERVICELINEUSERCONFIGV3", this.originalDataSource)
          // console.log(response.data)
          this.showPopup = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    soporte() {
      var data = {
        username: "develop@bifrost.pe",
        password: "developbifrost",
        search: "TEST2112",
        problem: "1",
        description: "Descripción Ticket Prueba Starlink",
        fullname: "Usuario Prueba",
        email: "leonardo25457@gmail.com",
      };
      store
        .dispatch("SOPORTE", data)
        .then((response) => {
          console.log(this.newService);
          store.dispatch("showToast", {
            message: "SE ENVIO EL TICKET DE SOPORTE",
            type: "success",
          });
          this.showPopupDrowSoporte = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    serviceplan() {
      var data = {
        endpointId: 17,
        token: this.token,
        principalId: this.user.principalId,
        platform: "ses",
        roleId: this.user.roleId,
        userId: this.user.id,
        orgId: this.user.orgId,
      };
      store
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          // store.dispatch("showToast", {
          //     message: "LISTADO DE PLANTA",
          //     type: "success",
          // });
          // this.$router.push("/home");
          // this.originalDataSource = response.data;
          console.log(response.data.results);
          this.changeserviceplan = response.data.data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    // problemtype() {
    //   store
    //     .dispatch("PROBLEMTYPE")
    //     .then((response) => {
    //       // store.dispatch("showToast", {
    //       //     message: "LISTADO DE PLANTA",
    //       //     type: "success",
    //       // });
    //       // this.$router.push("/home");
    //       // this.originalDataSource = response.data;
    //       console.log(response);
    //       this.problemtypes = response.data;
    //     })
    //     .catch((error) => {
    //       console.log("error", error);
    //     });
    // },

    problemtype() {
      store
        .dispatch("PROBLEMTYPE")
        .then((response) => {
          // store.dispatch("showToast", {
          //     message: "LISTADO DE PLANTA",
          //     type: "success",
          // });
          // this.$router.push("/home");
          // this.originalDataSource = response.data;
          console.log("problemtype", response);
          this.problemtypes = response.data.data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    cambioPlan(e: any) {
      store
        .dispatch("HELLOWORD")
        .then((response) => {
          console.log(this.changeplan);
          store.dispatch("showToast", {
            message: "SE CAMBIO EL PLAN EXISTOSAMENTE DE UN SERVICIO",
            type: "success",
          });
          // this.$router.push("/home");
          this.showPopupDrow = false;
          console.log("datos", e);
          var data = {
            body: `prueba solicitud de cambio de plan ${e.nickname} / ${e.kitSerialNumber}`,
            asunto: "Cambio de plan",
            correo_destino: "juliocesar.herranlarosa@telefonica.com",
          };
          store
            .dispatch("SEND_MAIL", data)
            .then((response) => { })
            .catch((error) => {
              console.log("error", error);
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    suspender(e: any) {
      store
        .dispatch("HELLOWORD")
        .then((response) => {
          store.dispatch("showToast", {
            message: "SE SUSPENDIO EXISTOSAMENTE UN SERVICIO",
            type: "success",
          });
          // this.$router.push("/home");
          this.showPopupDrowSus = false;
          var data = {
            body: `prueba solicitud de suspencion ${e.terminals_nickname} / ${e.kitSerialNumber}`,
            asunto: "baja total",
            correo_destino: "juliocesar.herranlarosa@telefonica.com",
          };
          store
            .dispatch("SEND_MAIL", data)
            .then((response) => { })
            .catch((error) => {
              console.log("error", error);
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    bajaTotal(e: any) {
      // console.log("USER BAJA", this.user)

      let dataBaja = {
        ...e,
        transaccion: "Baja",
        user_vno_id: (this.user as any).vno_id,
        user_vno: (this.user as any).vno_name
      }
      // console.log("ENTRO BAJA", dataBaja)
      store.dispatch("CREATE_REQUEST", dataBaja).then((response) => {
        // console.log(response)
        store.dispatch("showToast", {
          message: `Se ha solicitado una Baja de Servicio con el siteId: ${dataBaja.siteId}`,
          type: "success",
        });

        let msj = `<html>
        <head>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    line-height: 1.6;
                }
                .container {
                    max-width: 600px;
                    margin: auto;
                    padding: 20px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    background-color: #f9f9f9;
                }
            </style>
        </head>
        <body>
            <div class="container">
                <p>Buen dia.</p>
                <br>
                <p>Tiene una solicitud Pendiente</p>
                <p><strong>Movimiento: </strong> ${dataBaja.transaccion} de Servicio </p>
                <p><strong>SiteId: </strong> ${dataBaja.siteId}  </p>
                <p><strong>Numero de Serie Modem: </strong> ${e.esn}  </p>
                <p><strong>Plan de Servicio: </strong> ${e.servicesPlan_name}  </p>
                <br>
                <p>Cuando se ejecute, por favor actualizar el registro de la terminal en la bandeja de solcitudes de BIFROST</p>
                <br>
                <p>Saludos.</p>
                <p>Ordering</p>
            </div>
        </body>
        </html>`

        let subj = `SOLICITUD DE BAJA DE SERVICIO - CLIENTE ${dataBaja.user_vno}`

        let data = {
          to: ["yeltsinrhp.93@gmail.com"],
          cc: ["yeltsinrhp.93@hotmail.com"],
          subject: subj,
          message: msj
        }


        store.dispatch("ENVIAR_EMAILS", data).then((response) => {
          store.dispatch("showToast", {
            message: `Se envio un mensaje a los responsables`,
            type: "success",
          });
        })
        this.showPopupDrowBaja = false;
      }).catch((error) => {
        store.dispatch("showToast", {
          message: String(error.response.data.message[0]),
          type: "error",
        });
        this.showPopupDrowBaja = false;
      });

    },
    suspendRequest(e: any) {
      // console.log("USER BAJA", this.user)

      let dataSuspend = {
        ...e,
        transaccion: "suspend",
        user_vno_id: (this.user as any).vno_id,
        user_vno: (this.user as any).vno_name
      }
      // console.log("ENTRO SUSPENDER", dataSuspend)
      store.dispatch("CREATE_REQUEST", dataSuspend).then((response) => {
        // console.log(response)
        store.dispatch("showToast", {
          message: `Se ha solicitado una Baja de Servicio con el siteId: ${dataSuspend.siteId}`,
          type: "success",
        });

        let msj = `<html>
        <head>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    line-height: 1.6;
                }
                .container {
                    max-width: 600px;
                    margin: auto;
                    padding: 20px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    background-color: #f9f9f9;
                }
            </style>
        </head>
        <body>
            <div class="container">
                <p>Buen dia.</p>
                <br>
                <p>Tiene una solicitud Pendiente</p>
                <p><strong>Movimiento: </strong> ${dataSuspend.transaccion} de Servicio </p>
                <p><strong>SiteId: </strong> ${dataSuspend.siteId}  </p>
                <p><strong>Numero de Serie Modem: </strong> ${e.esn}  </p>
                <p><strong>Plan de Servicio: </strong> ${e.servicesPlan_name}  </p>
                <br>
                <p>Cuando se ejecute, por favor actualizar el registro de la terminal en la bandeja de solcitudes de BIFROST</p>
                <br>
                <p>Saludos.</p>
                <p>Ordering</p>
            </div>
        </body>
        </html>`

        let subj = `SOLICITUD DE SUSPENSION DE SERVICIO - CLIENTE ${dataSuspend.user_vno}`

        let data = {
          to: ["yeltsinrhp.93@gmail.com"],
          cc: ["yeltsinrhp.93@hotmail.com"],
          subject: subj,
          message: msj
        }


        store.dispatch("ENVIAR_EMAILS", data).then((response) => {
          store.dispatch("showToast", {
            message: `Se envio un mensaje a los responsables`,
            type: "success",
          });
        })
        this.showPopupDrowBaja = false;
      }).catch((error) => {
        store.dispatch("showToast", {
          message: String(error.response.data.message[0]),
          type: "error",
        });
        this.showPopupDrowBaja = false;
      });

    },
    generarTicket(data: any, terminal: any) {
      var dataEmail = {
        body: data.descripcion,
        asunto: `${data.request.name} - ${data.dataComunication} - ${terminal.esn}`,
        correo_destino: "nocmnla.fija.pe@telefonica.com",
      };

      store
        .dispatch("SEND_MAIL", dataEmail)
        .then((response) => {
          this.showPopupDrowSoporte = false;
          store.dispatch("showToast", {
            message: "SE ENVIO CON EXITO SU CONSULTA A SOPORTE",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error", error);
        });

      let created = {
        tableReference: "ses",
        referenceId: terminal.id,
        pinProblemId: data.request.id,
        description: data.descripcion,
        contactIncidence: this.user.fullname,
        mediaComunicationId: data.mediaComunication.id,
        dataComunication: data.mediaComunication.name,
        typeContactId: data.typeContact.id,
        platformId: 132,
        userId: this.user.id,
      };

      // console.log('created', created)
      store
        .dispatch("CREATEDSUPPORT", created)
        .then((response) => {
          store.dispatch("showToast", {
            message: "SE ENVIO CON EXITO SU CONSULTA A SOPORTE",
            type: "success",
          });
          // this.$router.push("/home");
          this.showPopupDrowSoporte = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    cargarArchivo() {
      store
        .dispatch("HELLOWORD")
        .then((response) => {
          store.dispatch("showToast", {
            message: "SE CARGO CORRECTAMENTE EL ARCHIVO",
            type: "success",
          });
          // this.$router.push("/home");
          this.showPopupDrowComisionamiento = false;
          var data = {
            body: "se cargo un archivo",
            asunto: "Nuevo archivo",
            correo_destino: "juliocesar.herranlarosa@telefonica.com",
          };
          store
            .dispatch("SEND_MAIL", data)
            .then((response) => { })
            .catch((error) => {
              console.log("error", error);
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    closePopup() {
      // if (!event.target.closest(".popup-form")) {
      this.showPopup = false;
      this.showPopupDrow = false;
      this.showPopupDrowSus = false;
      this.showPopupDrowBaja = false;
      this.showPopupDrowSuspend = false;
      this.showPopupDrowChangeModem = false;
      this.showPopupDrowSoporte = false;
      this.showPopupAddress = false;
      this.showPopupDrowComisionamiento = false;
      this.showPopupDrowAlta = false;
      this.showPopupChangePlan = false;
      this.showPopupReactivate = false;
      this.showPopupBajaValidation = false;
      this.showPopupFecha = false;
      this.terminalSelected = null;
      // }
    },
    confirmSubmitSuspender(event: any) {
      event.preventDefault();
      const confirmMessage = "¿Estás seguro de Suspender?";
      if (window.confirm(confirmMessage)) {
        // Aquí puedes realizar las acciones de envío del formulario
        console.log("Formulario enviado");
      }
    },
    confirmSubmitBaja(event: any) {
      console.log("ENTRO CONFIRM")
      event.preventDefault();
      const confirmMessage = "¿Estás seguro de Baja Total?";
      if (window.confirm(confirmMessage)) {
        // Aquí puedes realizar las acciones de envío del formulario
        console.log("Formulario enviado");
      }
    },
    confirmSubmitSoporte(event: any) {
      event.preventDefault();
      const confirmMessage = "¿Estás seguro de enviar tu Consulta?";
      if (window.confirm(confirmMessage)) {
        // Aquí puedes realizar las acciones de envío del formulario
        console.log("Formulario enviado");
      }
    },
    confirmSubmitComisionamiento(event: any) {
      event.preventDefault();
      const confirmMessage = "¿Estás seguro de enviar tu Consulta?";
      if (window.confirm(confirmMessage)) {
        // Aquí puedes realizar las acciones de envío del formulario
        console.log("Formulario enviado");
      }
    },
    terminalSelect(value: any) {
      // console.log("TERMINAL SELECT", value)
      this.terminalSelected = value;
      if (this.showPopupDrowSoporte) {
        this.handleSupportButtonClick();
      }
    },

    handleSupportButtonClick() {
      this.ticketSoporte.typeContact = this.problemtypes.dataTypeContactId[0]; // Asigna la primera opción
      this.ticketSoporte.dataTypeContact = this.user.fullname;
      this.ticketSoporte.dataComunication = this.user.email;
      this.ticketSoporte.mediaComunication =
        this.problemtypes.dataMediaComunicationId[0]; // Asigna la primera opción
    },

    peticionFecha(value: any) {
      this.terminalSelected = value;
      if (this.showPopupFecha) {
        this.handleSupportButtonClick();
      }
    },

    handleDateClick(date: Date) {
      // Manejar el evento de clic en una fecha si es necesario
      console.log("Fecha seleccionada:", date);
    },
    // generateGrafanaLink() {
    // if (this.dates && this.dates.length === 2 && this.dates[0] && this.dates[1]) {
    // const startDate = this.formatDate(this.dates[0]);
    // const endDate = this.formatDate(this.dates[1]);
    // const grafanaBaseUrl = 'https://bifrostgatewaydev.bifrost.pe/ses/';
    // return `${grafanaBaseUrl}/dashboard?from=${startDate}&to=${endDate}`;
    // }
    // return '#';
    // },
    formatDate(date: Date) {
      // Función para formatear la fecha en el formato deseado por Grafana
      // Ajusta esto según el formato de fecha requerido por tu instancia de Grafana
      const formattedDate = date.toISOString().split("T")[0];
      return formattedDate;
    },

    gotografanawithDate(terminal: any) {
      var data: { [key: string]: any } = {
        siteId: terminal.siteId,
        latitud: terminal.latitud,
        longitud: terminal.longitud,
      };
      const startDate = this.formatDate(this.dates[0]);
      const endDate = this.formatDate(this.dates[1]);
      data["var-principal"] = terminal.principal_id;
      data["startDate"] = startDate;
      data["endDate"] = endDate;
      store
        .dispatch("GETMONITORINGURL", data)
        .then((response) => {
          let url = response.data.snapshotUrl;
          const hasQueryParams = url.includes("?");
          const separator = hasQueryParams ? "&" : "?";
          const urlWithKioskParam = url + separator + "kiosk";
          window.open(urlWithKioskParam, "_blank");
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
});
</script>
<style lang="scss">
@import "vue-select/dist/vue-select.css";

.table-content {
  display: flex;
  align-items: flex-start !important;
  justify-content: center;
  flex-direction: column;
  padding-top: 127px;
  margin-bottom: 100px;
  max-width: 1630px;

  .buscar-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    max-width: 1630px;
  }
}

.menu-button {
  background-color: #15059088;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  img {
    width: 20px;
    height: 20px;
  }
}

.title-c {
  span {
    color: #000000 !important;
  }
}

.header-content {
  display: flex;
  justify-content: center;
  // flex-direction: row-reverse;
}

.header-close {
  display: flex;
  justify-content: flex-end;
}

.btn-inactive {
  border: none;
  background-color: #aaaaaa;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  padding: 10px;
}

.btn-active {
  border: none;
  background-color: #22bf03;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  padding: 10px;
}

.popup-form .group input {
  width: 100%;
  max-width: 320px;
}

.flexing-menu {
  display: flex;
  justify-content: space-between;
  width: 100%;

  img {
    flex-basis: 10%;
    display: flex;
    justify-content: center;
  }

  span {
    flex-basis: calc(90% - 5px);
    margin-left: 5px;
  }
}

.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.file-upload-label,
.upload-button {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  text-align: center;
}

.file-upload-label:hover,
.upload-button:hover {
  opacity: 0.8;
}

.file-upload-input {
  display: none;
}

.btn-content {
  margin-left: 10px;

  button {
    height: 40px;
    cursor: pointer;
    width: 100%;
    border-radius: 9px;
    background-color: #007bff;
    color: #ffffff;
  }
}

.p-column-title {
  color: #000000;
}

.p-datepicker-calendar span {
  color: #010408;
}

.btn-baja {
  height: 40px;
  cursor: pointer;
  width: 100%;
  max-width: 10rem;
  border-radius: 9px;
  background-color: #007bff;
  color: #ffffff;
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 15px;
  font-size: 16px;
  color: #fff;
  background-color: #0066FF;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  text-align: center;
  width: 218px;
  height: 40px;
}

.custom-file-upload input[type="file"] {
  display: none;
}
</style>
