<template>
  <div class="nav-1">
    <div class="conte-nav">

      <RouterLink style="z-index: 2!important;" to="/home"><img class="img-fluid" src="@/assets/telefon.png"
          alt="logo-servicios-leo" /></RouterLink>

      <div class="title1">{{ $t('message.title') }}</div>
      <div style="z-index: 1;" class="idioma">
        <img v-if="$i18n.locale === 'en'" class="bandera" src="@/assets/banderas/en.png" alt="en-bandera">
        <img v-if="$i18n.locale === 'es'" class="bandera" src="@/assets/banderas/es.png" alt="es-bandera">
        <img v-if="$i18n.locale === 'pt'" class="bandera" src="@/assets/banderas/brasil.png" alt="es-bandera">
        <select v-model="$i18n.locale" @change="setLocaleObserve($i18n.locale)">
          <option value="en">EN</option>
          <option value="es">ES</option>
          <option value="pt">PT</option>
        </select>
      </div>
      <!-- <div class="title2">Soporte</div> -->
      <div style="z-index: 2;" v-if="user" class="dropdown-menu">
        <button class="btn-burguer">
          <img style="margin-top: 3px; height: 22px; width: 22px; cursor: pointer" src="@/assets/menu.png" alt="" />
        </button>
        <div class="dropdown-content-menu">
          <router-link v-if="permissions.some((evt: any) => evt.id === 10)" to="/perfil">
            <img src="@/assets/checkbox-multipe.png" alt="editar-perfil" />
            <span> Editar Perfil </span>
          </router-link>


          <a v-if="permissions.some((evt: any) => evt.id === 55)" :href="supportUrl" target="_blank">
            <img src="@/assets/handshake.png" alt="soporte" />
            <span> {{ $t('message.home.btn0') }} </span>
          </a>


          <!-- <a href="https://supportdev.bifrost.pe/" target="_blank">
            <img src="@/assets/handshake.png" alt="cerrar-session" />
            <span> {{ $t('message.home.btn0')}} </span>
          </a> -->


          <a @click.prevent="logout()" href="#">
            <img src="@/assets/note-edit-outline.png" alt="cerrar-session" />
            <span> {{ $t('message.auth.logout') }} </span>
          </a>

        </div>


      </div>

    </div>

    <div v-if="user" class="user-data">
      <strong>
        <span>{{ user.fullname }}</span>
        <!-- <svg  width="30" height="30" viewBox="0 0 101 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M50.5 0.0625C22.9475 0.0625 0.53125 21.5675 0.53125 48C0.53125 74.4325 22.9475 95.9375 50.5 95.9375C78.0525 95.9375 100.469 74.4325 100.469 48C100.469 21.5675 78.0525 0.0625 50.5 0.0625ZM38.4354 26.9859C41.4792 23.8907 45.7626 22.1875 50.5 22.1875C55.2374 22.1875 59.4824 23.9022 62.5381 27.0135C65.6348 30.1663 67.141 34.4023 66.7855 38.9564C66.0744 48 58.7713 55.375 50.5 55.375C42.2287 55.375 34.9112 48 34.2145 38.9541C33.8614 34.3632 35.3652 30.1133 38.4354 26.9859ZM50.5 88.5625C44.8556 88.5661 39.2679 87.4823 34.0683 85.3753C28.8688 83.2684 24.1631 80.1812 20.2305 76.297C22.4828 73.2155 25.3526 70.5955 28.6723 68.5901C34.7959 64.8242 42.5458 62.75 50.5 62.75C58.4542 62.75 66.2041 64.8242 72.3205 68.5901C75.6429 70.5946 78.5153 73.2147 80.7695 76.297C76.8372 80.1817 72.1316 83.2691 66.932 85.3761C61.7323 87.483 56.1445 88.5666 50.5 88.5625Z" fill="#ffffff"/>
            </svg> -->
      </strong>
    </div>
  </div>
</template>

<script lang="ts">
import store from "@/store";
import { defineComponent } from "vue";
import { mapState, mapGetters } from 'vuex';
import { setLocale, removeToken } from "@/utils/auth";
import router from "@/router";
export default defineComponent({
  name: "Navbar",
  computed: {
    ...mapState({
      user: (state: any) => state.AUTH.user,
    }),
    ...mapGetters(["permissions"]),
  },
  data(){
    return{
      supportUrl: process.env.VUE_APP_RUTA_URL_SUPPORT,
    }
  },
  methods: {
    logout() {
      removeToken()
      store.commit("SET_USER", null);
      store.commit("SET_PERMISION", null);
      router.push('/')
    },
    setLocaleObserve(value: any) {
      setLocale(value);
    }
  },

});
</script>
<style>
.nav-1 {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 107px;
  background: rgba(20, 76, 158, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conte-nav {
  display: flex;
  justify-content: space-evenly;
  width: 600px;


}

.img-fluid {
  width: auto;
  height: 80px;
  margin: auto;
  /* margin-left: 40rem; */
}

.title1 {
  /* position: absolute; */
  width: auto;
  height: auto;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  margin: auto;
}

.title2 {
  /* position: absolute; */
  width: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  background-color: #0d0241bf;
  margin: auto;

  border-radius: 9px;
  cursor: pointer;
}

/* .title2:hover {
  width: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  background-color: #2001b9fe;
  margin: auto;
  margin-left: 30rem;
  border: 1px solid #bbbbbb;
  border-radius: 9px;
  cursor: pointer;
  font-weight: bold;
} */

.butn {
  box-sizing: border-box;
  width: 125px;
  height: 45px;
  background: rgba(217, 217, 217, 0.2);
  border: 2px solid #d1d1d1;
  border-radius: 10px;
  margin: auto;
  cursor: pointer;
  margin-right: 3rem;
}

/* .img-arrow{
      margin: auto;
  } */

.tip-btn {
  display: flex;
  justify-content: space-evenly;
  padding: 0.5rem;
}

.text-log {
  width: 57px;
  height: 21px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  /* margin: auto; */
}

.dropdown-menu {
  position: relative;
  display: inline-block;
  margin-top: 25px;
  margin-left: 15px;
}

.dropdown-content-menu {
  position: absolute;
  display: none;
  width: 194px;
  max-height: 102px;
  background: #0066ff;
  border-radius: 10px;
}

.dropdown-menu:hover .dropdown-content-menu {
  display: block;
}

.dropdown-content-menu a {
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px;
}

.dropdown-content-menu a img {
  flex-basis: 10%;
}

.dropdown-content-menu a span {
  flex-basis: 90%;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 25px;
  color: #ffffff;
  margin-left: 5px;
}

.dropdown-content-menu a:hover {
  background-color: #0079ff;
}

.btn-burguer {
  background-color: #2001b9fe;
  border: none;
}

@media screen and (min-width: 1024px) {
  .title2 {
    /* position: absolute; */
    width: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 41px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
    background-color: #0d0241bf;
    margin: auto;
    border-radius: 9px;
    cursor: pointer;
  }

  .idioma {
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .idioma select {
    width: 60px;
    height: 30px;
  }

  .idioma select option {
    height: 100%;
    width: 100%;
  }

  .idioma select option img {
    height: 45px;
    width: 45px;
  }
}

.user-data {
  position: absolute;
  top: 0px;
  right: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
}

.user-data strong span {
  color: #ffffff;
  font-weight: bold;
  font-size: 27px;
  margin-left: 5px;

}

.user-data strong svg {}

.bandera {
  height: 35px;
  width: 35px;
  margin-right: 5px;
}
</style>
