import Cookies from 'js-cookie'

const TokenKey = 'user-token'
const TokenKeyRefresh = 'user-token-refresh'
const Lang="lang"

export function getToken() {
  return Cookies.get(TokenKey)
}

export function getTokenRefresh() {
  return Cookies.get(TokenKeyRefresh)
}

export function setToken(token: any) {
  Cookies.set(TokenKey, token, {domain:".bifrost.pe"})
  return Cookies.set(TokenKey, token)
}
export function setTokenRefresh(token: any) {
  Cookies.set(TokenKeyRefresh, token, {domain:".bifrost.pe"})
  return Cookies.set(TokenKeyRefresh, token)
}
export function removeToken() {
  Cookies.remove(TokenKeyRefresh, {domain:".bifrost.pe"})
  Cookies.remove(TokenKey, {domain:".bifrost.pe"})
  Cookies.remove(TokenKeyRefresh)
  return Cookies.remove(TokenKey)
}

export function getLocale() {
  return Cookies.get(Lang)
}

export function setLocale(lang: any) {
  return Cookies.set(Lang, lang)
}

