import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import primeVue from "primevue/config";
import { createI18n } from "vue-i18n";
import { getLocale } from "@/utils/auth";
import "primevue/resources/themes/saga-blue/theme.css"; // Importar tema de PrimeVue
import "primevue/resources/primevue.min.css"; // Importar CSS de PrimeVue
import "primeicons/primeicons.css"; // Importar CSS de PrimeIcons
// console.log('ejecuta');
const messages = {
  en: {
    message: {
      export: "Export",
      title: "KA",
      auth: {
        logout: "Log out",
        login: "Login",
        f1: "Username",
        f2: "Password",
        f3: "Sign In",
        f4: "edit profile",
      },
      home: {
        btn0: "Support Center",
        btn1: "Device List",
        btn2: "New Service",
        btn3: "Monitoring",
        btn4: "Requests",
      },
      planta: {
        search: "Search",
        c1: "Customer",
        c2: "Site ID",
        c3: "Suscriptor Name",
        c4: "Modem",
        c5: "Status",
        c6: "Serial Number",
        c7: "Last connection",
        c8: "Daily Use (L.C.)",
        c9: "Cycle use GB",
        c10: "Service Plan",
        c11: "Address",
        c12: "Long",
        c13: "Lat",
        c14: "Options",
        op1: "Commissioning",
        op2: "Update Service Plan",
        op3: "Update Address",
        op4: "Suspend Service",
        op5: "Cancel Service",
        op6: "Support",
        op7: "Monitoring",
        op8: "Registration request",
        f1: "New Service",
        f2: "Address",
        f3: "Verify Address",
        f4: "Update Address",
        f5: "Site ID",
        f6: "select service plan",
        f7: "select serial number",
        f8: "New Service",
        f9: "Commissioning",
        f10: "Select File",
        f11: "Upload File",
        f12: "Update Service Plan",
        f13: "Select service plan",
        f14: "Update Service Plan",
        f15: "Long",
        f16: "Lat",
        f17: "New Address",
        f18: "Update Address",
        f19: "total discharge",
        f20: "Do you really want to cancel the service git",
        f21: "Cancel Service",
        f22: "Support",
        f23: "Contact Name",
        f24: "Contact e-mail",
        f25: "select Problem",
        f26: "Description",
        f27: "Send Ticket",
        f28: "Suspend Service",
        f29: "Do you really want to suspend the service ",
        f30: "Suspend Service",
        f31: "Select Date",
        f32: "High Request",
        f33: "Execute",
        f34: "Close",
        f35: "This action will terminate this service. Remember that this action cannot be undone !!",
        f36: "Low Request",
        f37: "Plan Change Request",
        f38: "Daily File",
        f39: "Suspend Request",
        f40: "This action will suspend this service",
        f41: "Modem Change",
        f42: "Reactivate",
        f43: "This action will reactivate this service"
      },
      solicitud: {
        s01: "ID",
        s02: "Date",
        s03: "Type of Requirement",
        s04: "SiteId",
        s05: "User",
        s06: "Status",
        s07: "Option",
        s08: "No information available"
      }
    },
  },
  es: {
    message: {
      export: "Exportar",
      title: "KA",
      auth: {
        logout: "Log out",
        login: "Iniciar sesión",
        f1: "Usuario",
        f2: "Contraseña",
        f3: "Ingresar",
        f4: "editar perfil",
      },
      home: {
        btn0: "Centro de Soporte",
        btn1: "Planta",
        btn2: "Nuevo Servicio",
        btn3: "Monitoreo",
        btn4: "solicitudes",
      },
      planta: {
        search: "Ingrese para realizar la busqueda…",
        c1: "Cliente",
        c2: "Site Id",
        c3: "Suscriptor",
        c4: "Serie del modem",
        c5: "Estado",
        c6: "Numero de Serie",
        c7: "última conexión",
        c8: "consumo diario (U.C.)",
        c9: "Consumo de ciclo GB",
        c10: "Plan de Servicio",
        c11: "Dirección",
        c12: "Longitud",
        c13: "Latitud",
        c14: "Acción",
        op1: "Comisionamiento",
        op2: "Cambio de plan",
        op3: "Cambio de Dirección",
        op4: "Suspender",
        op5: "Baja Total",
        op6: "Soporte",
        op7: "Tráfico y consumo",
        op8: "Solicitud de registro",
        f1: "Nuevo Servicio",
        f2: "Dirección",
        f3: "Verificar Dirección",
        f4: "Cambiar Dirección",
        f5: "Site Id",
        f6: "Seleccionar Plan de Servicio",
        f7: "Seleccionar Serial Number",
        f8: "Nuevo Servicio",
        f9: "Comisionamiento",
        f10: "Elegir archivo",
        f11: "Cargar Archivo",
        f12: "Cambio de plan",
        f13: "Seleccionar plan de servicio",
        f14: "Cambio de plan",
        f15: "Longitud",
        f16: "Latitud",
        f17: "Nueva Dirección",
        f18: "Cambiar Dirección",
        f19: "Baja Total",
        f20: "¿REALMENTE DESEA DAR DE BAJA TOTAL SU SERVICIO",
        f21: "Baja Total",
        f22: "Soporte",
        f23: "Nombre de contacto",
        f24: "Correo de contacto",
        f25: "Tipo de Problema",
        f26: "Descripción",
        f27: "Generar Ticket",
        f28: "SUSPENDER",
        f29: "¿REALMENTE DESEA SUSPENDER SU SERVICIO ",
        f30: "SUSPENDER",
        f31: "SELECCIONE LA FECHA",
        f32: "Solicitud Alta",
        f33: "Ejecutar",
        f34: "Cerrar",
        f35: "Esta acción dará de baja a este servicio. Recuerde que esta acción no se podrá deshacer !!",
        f36: "Solicitud Baja",
        f37: "Solicitud Cambio Plan",
        f38: "Archivo Diario",
        f39: "Solicitud Suspender",
        f40: "Esta acción suspenderá este servicio",
        f41: "Cambio de Modem",
        f42: "Reactivar",
        f43: "Esta acción reactivará este servicio"
      },
      solicitud: {
        s01: "ID",
        s02: "Fecha",
        s03: "Tipo de Requerimiento",
        s04: "SiteId",
        s05: "Usuario",
        s06: "Estado",
        s07: "Opcion",
        s08: "No hay información disponible"
      }
    },
  },
  pt: {
    message: {
      export: "Exportar",
      title: "KA",
      auth: {
        logout: "Sair",
        login: "Iniciar sessão",
        f1: "Usuário",
        f2: "Senha",
        f3: "Entrar",
        f4: "editar perfil",
      },
      home: {
        btn0: "Centro de Apoio",
        btn1: "Lista de Dispositivos",
        btn2: "Novos Serviços",
        btn3: "Monitoramento",
        btn4: "solicitações",
      },
      planta: {
        search: "Buscar…",
        c1: "Cliente",
        c2: "Site ID",
        c3: "Nome do Cliente",
        c4: "Modem",
        c5: "Status",
        c6: "Número de Série",
        c7: "Última Conexão",
        c8: "Consumo Diário (UC)",
        c9: "Consumo do Ciclo GB",
        c10: "Plano contratado",
        c11: "Endereço",
        c12: "Longitude",
        c13: "Latitude",
        c14: "Ações",
        op1: "Comissionamento",
        op2: "Atualização do Plano contratado ",
        op3: "Atualização Endereço",
        op4: "Suspender Serviço",
        op5: "Cancelar Serviço",
        op6: "Suporte",
        op7: "Monitoramento",
        op8: "Solicitação de registro",
        f1: "NOVO SERVIÇO",
        f2: "Endereço",
        f3: "Verificar o Endereço",
        f4: "Atualização Endereço",
        f5: "Site ID",
        f6: "Selecionar Plano contratado",
        f7: "Selecionar Número de Série",
        f8: "Novo Serviço",
        f9: "COMISSIONAMENTO",
        f10: "Selecionar Arquivo",
        f11: "Carregar Arquivo",
        f12: "ATUALIZAR PLANO DE SERVIÇO",
        f13: "Selecionar Arquivo",
        f14: "Atualização do Plano contratado ",
        f15: "Longitud",
        f16: "Latitud",
        f17: "Novo Endereço",
        f18: "Atualização Endereço",
        f19: "CANCELAR SERVIÇO",
        f20: "Cancelar o serviço ",
        f21: "Cancelar Serviço",
        f22: "APOIAR",
        f23: "Nome de Contato",
        f24: "Email de Contato",
        f25: "Selecione o Problema",
        f26: "Descrição",
        f27: "Enviar Ticket",
        f28: "SUSPENDER SERVIÇO",
        f29: "Deseja suspender o serviço ",
        f30: "Suspender Serviço",
        f31: "Selecione a Data",
        f32: "Pedido Alto",
        f33: "Corrida",
        f34: "Fechar",
        f35: "Essa ação encerrará o serviço. Observe que essa ação não pode ser desfeita !!",
        f36: "Solicitação de cancelamento do registro",
        f37: "Solicitação de mudança de plano",
        f38: "Arquivo Diárioo",
        f39: "Solicitação Demitir",
        f40: "Esta ação suspenderá este serviço",
        f41: "Mudança de modem",
        f42: "Reativação",
        f43: "Essa ação reativará o serviço"
      },
      solicitud: {
        s01: "ID",
        s02: "Data",
        s03: "Tipo de Requisito",
        s04: "SiteId",
        s05: "Do utilizador",
        s06: "Estado",
        s07: "Opção",
        s08: "Nenhuma informação disponível"
      }
    },
  },
};
const i18n = createI18n({
  locale: getLocale() ? getLocale() : "en",
  fallbackLocale: "en",
  warnHtmlInMessage: "off",
  messages,
});
createApp(App).use(store).use(router).use(primeVue).use(i18n).mount("#app");
