<template>
     <video
      src="@/assets/Galaxy.mp4"
      autoplay
      muted
      loop
      playsinline
      class="video_bg large"
      id="videoID"
    ></video>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VideoBackground',
});
</script>
<style>
.video_bg {
    z-index: -1;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>