import request from '@/utils/aplication'
const obj = {
  helloword() {
    return request({
      url: '/auth/private',
      method: 'get'
    })
  },
  validAddressStarlink(values:any) {
    return request({
      url: '/function',
      method: 'post',
      data: values
    })
  },
  validAddressStarlinkput(values:any) {
    return request({
      url: '/function',
      method: 'put',
      data: values
    })
  },
}
export default obj