import request from '@/utils/request'
import requestOrcher from '@/utils/aplication'
const obj = {
  login(values: any) {
    return request({
      url: '/login',
      method: 'post',
      data: values
    })
  },
  register(values: any) {
    return request({
      url: '/register',
      method: 'post',
      data: values
    })
  },
  refresh() {
    return request({
      url: '/refresh',
      method: 'post',
    })
  },
  logout() {
    return request({
      url: '/logout',
      method: 'post',
    })
  },
  update(id: string, values: any) {
    return request({
      url: '/update/' + id,
      method: 'post',
      data: values
    })
  },
  permision(values:any) {
    return requestOrcher({
      url: "/orchestatorv3/userData",
      method: "post",
      data: values,
    })
  },
  refresh_token(values:any) {
    return request({
      url: '/refresh',
      method: 'post',
      data: values,
    })
  },
}
export default obj