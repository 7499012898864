<template>
  <div class="maxiem">
    <div class="perfil-content">
      <div class="tags-content">
        <button @click.prevent="mode = 'perfil'" :class="mode === 'perfil' ? 'active' : 'tag'">
          Perfil
        </button>
        <button @click.prevent="mode = 'seguridad'" :class="mode === 'seguridad' ? 'active' : 'tag'">
          Seguridad
        </button>
      </div>
      <h3 v-if="user">Bienvenido {{ user.fullname }}</h3>
      <div v-if="mode === 'perfil'" class="formulario-user-data">
        <form @submit.prevent="">
          <div class="form-group">
            <label for="username">Usuario</label>
            <input required disabled v-model="user.email" class="input-login" type="text" id="username"
              placeholder="Ingresa tu usuario" />
          </div>
          <div class="form-group">
            <label for="username">Nombre Completo</label>
            <input required v-model="user.fullname" class="input-login" type="text" id="username"
              placeholder="Ingresa tu nombre" />
          </div>
        </form>
        <div class="footer-content">
          <button @click.prevent="updatedPerfil(user)" class="btn-submit">
            Guardar
          </button>
        </div>
      </div>
      <div v-else class="formulario-user-data">
        <form @submit.prevent="">
          <div class="form-group">
            <label for="password">Contraseña</label>
            <input required v-model="newpassword.password" class="input-login" type="password" id="password"
              placeholder="Ingresa tu nueva contraseña" />
          </div>
          <div class="form-group">
            <label for="password">Confirma Contraseña</label>
            <input required v-model="newpassword.confirm_password" class="input-login" type="password" id="password"
              placeholder="confirma tu nueva contraseña" />
          </div>
        </form>
        <div class="footer-content">
          <button @click.prevent="updatedPassword(newpassword)" class="btn-submit">
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import store from "../../store";
import router from "@/router"
export default defineComponent({
  name: "perfil",
  data() {
    return {
      mode: "perfil",
      newpassword: {
        password: "",
        confirm_password: "",
      },
    };
  },
  computed: {
    ...mapState({
      user: (state: any) => state.AUTH.user,
    }),
  },
  mounted() {
    console.log(this.user, "userr");
  },
  methods: {
    updatedPerfil(user: any) {
      store
        .dispatch("UPDATED", user)
        .then((response) => {
          store.dispatch("showToast", {
            message: "Perfil editado correctamente",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    updatedPassword(password: any) {
      if (password.password != password.confirm_password) {
        store.dispatch("showToast", {
          message: "las contraseñas no coinciden",
          type: "error",
        });
      } else {
        password.id = this.user.id;
        store
          .dispatch("UPDATED_PASSWORD", password)
          .then((response) => {
            store.dispatch("showToast", {
              message: "Password editado correctamente",
              type: "success",
            });
            router.push("/");
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    },
  },
});
</script>
<style scoped>
.maxiem {
  height: 100vh;
  display: flex;
  align-items: center;
}

.perfil-content {
  margin-top: 90px;
  background-color: #ffffff;
  min-height: 400px;
  min-width: 1100px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.perfil-content h3 {
  margin-left: 15px;
}

.formulario-user-data {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  height: 350px;
}

.formulario-user-data form {
  flex-basis: 85%;
}

.formulario-user-data .footer-content {
  flex-basis: 15%;
}

.input-login {
  width: 100%;
  max-width: 282px;
  padding: 8px;
  background-color: #d0d0d0;
  color: #000;
  border: none;
  border-radius: 4px;
  margin-right: 5px;
  /* Agregado */
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.btn-submit {
  width: 120px;
  padding: 10px;
  background-color: #0057b8;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  background-color: #2001b9fe;
  border: 1px solid #bbbbbb;
}

.tag {
  background-color: #2001b9fe;
  width: 120px;
  color: #ffffff;
  height: 30px;
  border-color: #ffffff;
}

.active {
  background-color: #ffffff;
  width: 120px;
  color: #2001b9fe;
  height: 30px;
  font-weight: bold;
  border-color: #2001b9fe;
}
</style>
