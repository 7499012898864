<template>
    <div style="z-index: 1" class="table-content">
        <div class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <InputText v-model="globalFilter" placeholder="Buscar en la tabla" class="p-mr-2" />
        </div>

        <DataTable :value="filteredCustomers" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]"
            tableStyle="min-width: 80rem" :globalFilter="globalFilter">
            <template #empty>
                <p>{{ $t("message.solicitud.s08") }}</p>
            </template>
            <Column field="id" style="width: 10%">
                <template #header>
                    <span class="custom-header">{{ $t("message.solicitud.s01") }}</span>
                </template>
            </Column>
            <Column field="created_at" style="width: 15%">
                <template #header>
                    <span class="custom-header">{{ $t("message.solicitud.s02") }}</span>
                </template>
            </Column>
            <Column field="transaccion" style="width: 15%">
                <template #header>
                    <span class="custom-header">{{ $t("message.solicitud.s03") }}</span>
                </template>
            </Column>
            <Column field="siteId" style="width: 10%">
                <template #header>
                    <span class="custom-header">{{ $t("message.solicitud.s04") }}</span>
                </template>
            </Column>
            <Column field="user_email" style="width: 20%">
                <template #header>
                    <span class="custom-header">{{ $t("message.solicitud.s05") }}</span>
                </template>
            </Column>
            <Column style="width: 10%">
                <template #header>
                    <span class="custom-header">{{ $t("message.solicitud.s06") }}</span>
                </template>
                <template #body="slotProps">
                    <Tag :value="slotProps.data.master_status_name" :severity="getSeverity(slotProps.data)" />
                </template>
            </Column>
            <!-- <Column v-if="permissions.some((evt: any) => evt.id === 118)" header="Opciones" style="width: 20%">
                <template #body="slotProps">
                    <div v-if="!['Procesado', 'Cancelado'].includes(slotProps.data.master_status_name)">
                        <div class="dropdown-container">
                            <Dropdown :options="getOptions(slotProps.data)" optionLabel="label"
                                @change="onDropdownChange(slotProps.data, $event.value)" class="custom-dropdown" />
                            <i class="pi pi-cog gear-icon"></i>
                        </div>
                    </div>
                </template>
            </Column> -->
            <Column v-if="permissions.some((evt: any) => evt.id === 118)" style="width: 20%">
                <template #header>
                    <span class="custom-header">{{ $t("message.solicitud.s07") }}</span>
                </template>
                <template #body="slotProps">
                    <div v-if="!['Procesado', 'Cancelado'].includes(slotProps.data.master_status_name)">
                        <div class="dropdown-container">
                            <Dropdown :options="getOptions(slotProps.data)" optionLabel="label"
                                @change="onDropdownChange(slotProps.data, $event.value)" class="custom-dropdown" />
                            <i class="pi pi-cog gear-icon"></i>
                        </div>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
    <ConfirmationDialog :visible="displayConfirmationDialog" :message="confirmationMessage"
        @hide="hideConfirmationDialog" @confirm="confirmOptionChange(selectedOptionData, selectedOptionStatus)" />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Tag from 'primevue/tag';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import 'primeicons/primeicons.css'
import ConfirmationDialog from './components/confirmationDialog.vue';
import store from "../../store/index";
import { mapGetters } from 'vuex';

export default defineComponent({
    name: "request",
    components: {
        DataTable,
        Column,
        Tag,
        Dropdown,
        InputText,
        ConfirmationDialog
    },
    data() {
        return {
            customers: [] as any[],
            globalFilter: '',
            displayConfirmationDialog: false,
            selectedOptionData: null,
            selectedOptionStatus: '',
            confirmationMessage: '¿Estás seguro que quieres cambiar el estado?'
        };
    },
    computed: {
        ...mapGetters(["permissions"]),
        filteredCustomers(): any[] {
            if (!this.globalFilter) {
                return this.sortedCustomers;
            }

            const filterText = this.globalFilter.toLowerCase();
            return this.sortedCustomers.filter(customer => {
                return (
                    customer.id.toString().toLowerCase().includes(filterText) ||
                    customer.created_at.toLowerCase().includes(filterText) ||
                    customer.transaccion.toLowerCase().includes(filterText) ||
                    customer.siteId.toString().toLowerCase().includes(filterText) ||
                    customer.user_email.toLowerCase().includes(filterText) ||
                    customer.master_status_name.toLowerCase().includes(filterText)
                );
            });
        },
        sortedCustomers(): any[] {
            if (!this.customers || this.customers.length === 0) {
                return [];
            }
            return this.customers.sort((a, b) => {
                // Ordenar por estado
                const statusComparison = this.getStatusWeight(a.master_status_name) - this.getStatusWeight(b.master_status_name);
                if (statusComparison !== 0) {
                    return statusComparison;
                }
                // Si los estados son iguales, ordenar por fecha
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            });
        }
    },
    mounted() {
        this.serviceline({
            endpoint: 80,
            type: 1,
        });
    },
    methods: {
        serviceline(data: any) {
            store
                .dispatch("SERVICELINEUSERCONFIGV3", data)
                .then((response) => {
                    store.dispatch("showToast", {
                        message: "LISTADO LAS SOLICITUDES",
                        type: "success",
                    });
                    this.customers = response.data.data;
                    this.assignIds();
                })
                .catch((error) => {
                    console.log("error", error);
                });
        },
        assignIds() {
            this.customers.sort((a, b) => {
                const statusComparison = this.getStatusWeight(a.master_status_name) - this.getStatusWeight(b.master_status_name);
                if (statusComparison !== 0) {
                    return statusComparison;
                }
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            });
        },
        getStatusWeight(status: string): number {
            switch (status) {
                case 'Pendiente':
                    return 1;
                case 'Procesado':
                    return 2;
                case 'Cancelado':
                    return 3;
                default:
                    return 4;
            }
        },
        getSeverity(data: any) {
            switch (data.master_status_name) {
                case 'Procesado':
                    return 'success';
                case 'Pendiente':
                    return 'warning';
                case 'Cancelado':
                    return 'danger';
                default:
                    return undefined;
            }
        },
        getOptions(data: any) {
            return [
                { label: 'Procesado' },
                { label: 'Cancelado' }
            ];
        },

        onDropdownChange(data: any, status: string) {
            this.onOptionChange(data, status);
        },

        onOptionChange(data: any, status: string) {
            this.selectedOptionData = data;
            this.selectedOptionStatus = status;
            this.displayConfirmationDialog = true;
        },
        confirmOptionChange(data: any, status: string) {
            let dataConfirm = {
                ...data,
                status_confirm: (status as any).label
            }
            console.log('Opción seleccionada data 2:', dataConfirm);

            store.dispatch("UPDATE_REQUEST_STATUS", dataConfirm).then((response) => {
                store.dispatch("showToast", {
                    message: `Se ha ${dataConfirm.status_confirm} un ${dataConfirm.transaccion} con el siteId: ${dataConfirm.siteId}`,
                    type: "success",
                });

                let msj = `<html>
                <head>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            line-height: 1.6;
                        }
                        .container {
                            max-width: 600px;
                            margin: auto;
                            padding: 20px;
                            border: 1px solid #ccc;
                            border-radius: 5px;
                           	background-color: #f9f9f9;
                        }
                    </style>
                </head>
                <body>
                    <div class="container">
                        <p>Estimado Equipo SES</p>
                        <br>
                        ${dataConfirm.transaccion == "Alta"
                        ? '<p>Por favor incluir esta nueva Alta para que se propocione data y telemetria</p>'
                        : dataConfirm.transaccion == "Baja"
                            ? "Eliminar la terminal, ya que fue dado de Baja"
                            : "Cambiar de Plan de la terminal"}
                        <p><strong>SiteId: </strong> ${dataConfirm.siteId}  </p>
                        <br>
                        <p>Saludos.</p>
                        <p>Ordering</p>
                    </div>
                </body>
                </html>`

                let subj = `Disponibilidad de Terminales en API en  ${dataConfirm.vno_id}`

                let data = {
                    to: ["yeltsinrhp.93@gmail.com"],
                    cc: ["yeltsinrhp.93@hotmail.com"],
                    subject: subj,
                    message: msj
                }

                if (dataConfirm.status_confirm != "Cancelado") {
                    store.dispatch("ENVIAR_EMAILS", data).then((response) => {
                        store.dispatch("showToast", {
                            message: `Se envio un mensaje a los responsables`,
                            type: "success",
                        });
                    })
                }

                setTimeout(() => {
                    this.serviceline({
                        endpoint: 80,
                        type: 1,
                    });

                }, 5000);

                this.hideConfirmationDialog();
            }).catch((error) => {
                store.dispatch("showToast", {
                    message: String(error.response.data.message[0]),
                    type: "error",
                });
                this.hideConfirmationDialog();
            });
        },
        hideConfirmationDialog() {
            this.displayConfirmationDialog = false;
        }
    }
});
</script>




<style lang="scss">
.table-content {
    display: flex;
    align-items: flex-start !important;
    justify-content: center;
    flex-direction: column;
    padding-top: 127px;
    margin-bottom: 100px;
    max-width: 1630px;
}

.p-mr-2 {
    margin-right: 0.5rem;
}

.p-input-icon-left {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .pi {
        position: absolute;
        left: 0.75rem;
        top: 50%;
        transform: translateY(-50%);
    }

    .p-inputtext {
        padding-left: 4rem; // Ajusta este valor según el tamaño del icono y su padding
    }
}

.custom-split-button {
    width: 4rem; // Ancho del botón
    height: 2.5rem; // Altura del botón
    display: flex;
    align-items: center;
    justify-content: center;

    .pi {
        font-size: 1.5rem;
        margin: 0;
    }

    .span {
        color: black;
    }
}

.custom-dropdown {
    width: 100%;
    height: 2rem;
    font-size: 0.875rem;
    position: relative;

    .pi {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
    }
}

.dropdown-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 50%;
}

.gear-icon {
    position: absolute;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 1;
}
.custom-header {
    font-weight: bold;
    color: #5a5a5a;
    text-align: center;
}
</style>
