<template>
  <div>
    <router-view name="header" />
    <router-view/>
    <router-view name="background"/>
  </div>
  
</template>
<script lang="ts">
import { defineComponent } from "vue";
import {
    getToken, getTokenRefresh,removeToken
} from '@/utils/auth'
import store from './store'
import router from "@/router"

export default defineComponent({
  name: "servicios-leo",
  mounted () {
    if (getToken() && getTokenRefresh()) {
      store.dispatch("PERMISION",{"type": 2, "endpoint": 40})
             .then(response => {
              // this.$router.push('/home')
              store.dispatch('showToast', {
              message: "Sesion Recuperada",
              type: "success"
              })
              // this.$router.push('/home')
              })
             .catch(error =>{
              removeToken();
              router.push('/')
                 console.log('error', error)});
    }
  },
});
</script>

<style lang="scss">
@import './assets/css/normaliza.css';
body{
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  
}
</style>
