<template>
  <Popup>
    <div class="header-content">
      <img @click="closePopup" style="cursor: pointer; margin-top: -1rem" width="25" height="25"
        src="@/assets/eliminar-50.png" alt="delete-sign--v1" />
      <h1 style="
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 3rem;
        color: white;
        font-weight: 100;
      ">
        {{ $t('message.planta.f1')}}
      </h1>
    </div>

    <div>
      <form class="popup-form" @submit.prevent="verificarAddress()">
        <div class="group">
          <input required v-model="newService.direction" class="custom-input" type="text" :placeholder="$t('message.planta.f2')"
            style="padding-left: 0.5rem" />
        </div>
        <div class="btn-content">
          <button class="btn-submit-address" type="submit">
            {{
              coordenate.length > 0
              ? $t('message.planta.f3')
              : $t('message.planta.f4')
            }}
          </button>
        </div>
      </form>
    </div>

    <div style="margin: 20px 0px" v-if="validAddress">
      <Map :height="200" :width="320"></Map>
    </div>

    <form v-if="validAddress" class="popup-form" @submit.prevent="newServices()">
      <div class="group">
        <input v-model="coordenate.params.nickname" class="custom-input" type="text" required :placeholder="$t('message.planta.f5')"
          style="padding-left: 0.5rem" />
      </div>

      <div class="group">
        <v-select v-model="coordenate.params.planservice" :options="changeplan" label="name" required
          :placeholder="$t('message.planta.f6')" class="custom-select" />
      </div>
      <div v-if="newData" class="group">
        <input disabled v-model="coordenate.params.regionCode" class="custom-input" type="text" :placeholder="$t('message.planta.c11')"
          style="padding-left: 0.5rem" />
      </div>
      <div v-if="newData" class="group">
        <input disabled v-model="coordenate.params.latitude" class="custom-input" type="text" :placeholder="$t('message.planta.f16')"
          style="padding-left: 0.5rem" />
      </div>
      <div v-if="newData" class="group">
        <input disabled v-model="coordenate.params.longitude" class="custom-input" type="text" :placeholder="$t('message.planta.f15')"
          style="padding-left: 0.5rem" />
      </div>
      <div class="group">
        <v-select required v-model="coordenate.params.device" :options="serial" label="kitSerialNumber"
          :placeholder="$t('message.planta.f7')" class="custom-select" />
      </div>
      <!-- <div class="group">
            <v-select
              v-model="newService.suscriptor"
              :options="suscriptores"
              label="name"
              :placeholder="'Seleccionar Suscriptor'"
              class="custom-select"
            />
          </div> -->

      <div class="btn-content">
        <button :disabled="process" class="btn-submit" type="submit" style="margin-left: 1rem">
          <div style="
              display: flex;
              justify-content: space-evenly;
              width: 90%;
              margin: auto;
              align-items: center;
            ">
            <div>{{$t('message.planta.f8')}}</div>
            <div>
              <img src="@/assets/Vector-servicio.png" alt="img-vector" />
            </div>
          </div>
        </button>
      </div>
    </form>
  </Popup>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Popup from "../../components/planta-conte/Popup.vue";
import store from "../../store";
import "@/assets/sass/StylesPlanta.scss";
import { mapState, mapGetters } from "vuex";
import Map from "../../components/map/index.vue";
import vSelect from "vue-select";

export default defineComponent({
  name: "newService",
  emits: ["close"],
  components: {
    Popup,
    Map,
    vSelect,
  },
  computed: {
    ...mapState({
      coordenate: (state: any) => state.AUTH.coordenateData,
      user: (state: any) => state.AUTH.user,
      token: (state: any) => state.AUTH.token
    }),
    ...mapGetters(["permissions"]),

  },
  data() {
    return {
      showPopup: false,
      showPopupDrow: false,
      showPopupDrowSus: false,
      showPopupDrowBaja: false,
      showPopupDrowSoporte: false,
      validAddress: false,
      process: false,
      newService: {
        nickname: "",
        planservice: "",
        direction: "",
        kitSerialNumber: "",
        suscriptor: "",
        addressLines: "",
        locality: "",
        administrativeArea: "", // mayuscula
        administrativeAreaCode: "", // mayuscula
        region: "",
        regionCode: "", // mayuscula
        postalCode: "",
        latitude: "",
        longitude: "",
        formattedAddress: "",
        productReferenceId: "",
      },
      newData: {
        nickname: null,
        device: null,
        planservice: null,
      },
      changeplan: [],
      suscriptores: [],
      serial: [],
    };
  },
  mounted() {
    this.serviceplan();
    this.customer();
    this.serialnumber();
  },
  methods: {
    closePopup() {
      this.$emit("close");
    },
    newServices() {
      this.process = true;
      if (this.permissions.some((evt: any) => evt.id === 38)) {
        store.dispatch("GET_VALID_COORDENADE", this.coordenate).then((response2) => {
              if (response2.data.isValid) {
                this.validAddress = true;
                this.newData = response2.data.data.params;
                store.dispatch("showToast", {
                  message: "Direccion Válida",
                  type: "success",
                });
                var data = {
                  endpointId: 7,
                  principalId: this.user.principalId,
	                platform: "ses",
	                roleId: this.user.roleId,
	                userId: this.user.id,
	                orgId: this.user.orgId,
                  params: this.newData
                }
                  store.dispatch("GET_VALID_COORDENADE", data).then((response) => {
                    if (response.data.isValid) {
                      store.dispatch("showToast", {
                        message: "Creado correctamente",
                        type: "success",
                      });
                      this.$emit("close");
                      this.process = false;
                    } else {
                      this.process = false;
                      store.dispatch("showToast", {
                        message: response.data.message[0],
                        type: "error",
                      });
                    }
                      this.enviarCorreo(this.newData)

                    }).catch((error) => {
                      this.process = false;
                      store.dispatch("showToast", {
                        message: String(error.response.data.message[0]),
                        type: "error",
                      });
                    });

              } else {
                this.validAddress = false;
                store.dispatch("showToast", {
                  message: response2.data.message[0],
                  type: "error",
                });
              }
              console.log(response2);
            }).catch((error) => {
              this.validAddress = false;
              store.dispatch("showToast", {
                message: String(error.response.data.message[0]),
                type: "error",
              });
            });
            
      } else {
        this.process = false;
        store.dispatch("showToast", {
          message: "NO TIENES PERMISOS PARA ESTA ACCION",
          type: "error",
        });
      }

    },

    serviceplan() {
      var data = {
        endpointId: 17,
        token: this.token,
        principalId: this.user.principalId,
        platform: "ses",
        roleId: this.user.roleId,
        userId: this.user.id,
        orgId: this.user.orgId,
      }
      store
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          console.log(response.data.results);
          this.changeplan = response.data.data;
          this.changeplan = response.data.data.map(function (
            cadena: any,
          ) {
            return { id: cadena.id, name: cadena.name, productReferenceId: cadena.productReferenceId };
          });
          this.showPopup = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    customer() {
      var data = {
        endpointId: 4,
        token: this.token,
        principalId: this.user.principalId,
        platform: "ses",
        roleId: this.user.roleId,
        userId: this.user.id,
        orgId: this.user.orgId,
      }
      store
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          this.suscriptores = response.data.data;
          this.suscriptores = response.data.data.map(function (
            cadena: any,
          ) {
            return cadena.name;
          });
          this.showPopup = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    serialnumber() {
      var data = {
        endpointId: 8,
        token: this.token,
        principalId: this.user.principalId,
        platform: "ses",
        roleId: this.user.roleId,
        userId: this.user.id,
        orgId: this.user.orgId,
      }
      store
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          this.serial = response.data.data;
          this.serial = response.data.data.map(function (
            cadena: any,
          ) {
            return { id: cadena.id, userTerminalId: cadena.userTerminalId, kitSerialNumber: cadena.kitSerialNumber };
          });
          this.showPopup = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
      },
    verificarAddress() {
          var dataValid = {
            endpointId: 12,
            principalId: this.user.principalId,
            platform: "ses",
            roleId: this.user.roleId,
            userId: this.user.id,
            orgId: this.user.orgId,
            xparams: {
              direction: this.newService.direction
            }
         }
         store.dispatch("SERVICELINEUSERCONFIG", dataValid).then((response2) => {
               if (response2.data.isValid) {
               this.validAddress = true;
              if (response2.data.data.results.length>0) {
         
                var dataValue= response2.data.data.results[0];
                var country = {
                  long_name:null,
                  short_name:null
                };
                var postalCodeRef = {
                  long_name:null,
                  short_name:null
                };
                var localCodeRef = {
                  long_name:null,
                  short_name:null
                };
                dataValue.address_components.forEach((component:any)=> {
                  component.types.forEach((evt:any) => {
                      if (evt==="country") {
                          country = component;
                      }
                      if (evt==="postal_code") {
                        postalCodeRef = component;
                      }
                      if (evt==="locality") {
                        localCodeRef = component;
                      }
                    })
                });
                var dataValid = {
                    endpointId: 6,
                    principalId: this.user.principalId,
	                  platform: "ses",
	                  roleId: this.user.roleId,
	                  userId: this.user.id,
	                  orgId: this.user.orgId,
                    params: {
                       nickname:null,
                       device:null,
                       planservice:null,
                       userId:this.user.id,
                       accountNumber: this.user.accountNumber,
                       principalId: this.user.principalId,
                       platformId: this.user.platformId,
                       addressLines: response2.data.data.results[0].formatted_address,
                       locality: localCodeRef ? localCodeRef.long_name : "",
                       administrativeArea: country ? country.short_name : "", // mayuscula
                       administrativeAreaCode: country ? country.short_name : "", // mayuscula
                       region: country ? country.long_name : "",
                       regionCode: country ? country.short_name : "", // mayuscula
                       latitude: response2.data.data.results[0].geometry.location.lat,
                       longitude: response2.data.data.results[0].geometry.location.lng,
                       formattedAddress: response2.data.data.results[0].formatted_address,
                       postalCode: postalCodeRef ? postalCodeRef.long_name : "",
                    }
                  }
                  store.commit("SET_COORDENATE", dataValid);
                } else {
                  store.dispatch("showToast", {
                    message: "NO ENCONTRO DIRECCIÓN",
                    type: "error",
                  });
                }
                store.dispatch("showToast", {
                  message: "VERIFICADO",
                  type: "success",
                });
               } else {
                // this.validAddress = false;
                 store.dispatch("showToast", {
                   message: response2.data.message[0],
                   type: "error",
                 });
               }
              console.log(response2);
            }).catch((error) => {
              this.validAddress = false;
              store.dispatch("showToast", {
                message: String(error.response.data.message[0]),
                type: "error",
              });
            });
         
      
    },

    enviarCorreo(value: any) {
      var data = {
        body: `Estimados Buen día.
        Se realizó el alta del servicio ${value.nickname} asociado al KIT ${value.device.kitSerialNumber}, 
        se requiere actualizar la politica de IP a "Public IP" en plataforma Starlink.`,
        asunto: `ALTA DE SERVICIO ${value.nickname}`,
        correo_destino: "nocmnla.fija.pe@telefonica.com",
        cc: ["alejandro.chacon@telefonica.com","orderingmn.pe@telefonica.com","elvis.shahuano@wydsoluciones.com","andres.tello@wydsoluciones.com"]
      };
      store
        .dispatch("SEND_MAIL", data)
        .then((response) => {
          store.dispatch("showToast", {
            message: "CORREO ENVIADO EXITOSAMENTE",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error", error);
        });

    },
  },
});
</script>
<style lang="scss">
.dropdown-content {
  position: fixed;
  display: none;
  width: 160px;
  height: 225px;
  background: #0066ff;
  border-radius: 10px;
  margin-top: 0px;
}

.dropdown-content:last-of-type {
  position: fixed;
  display: none;
  width: 160px;
  height: 225px;
  background: #0066ff;
  border-radius: 10px;
  margin-top: -225px;
  margin-left: 45px;
}

.dropdown-content:hover {
  position: fixed;
  display: none;
  width: 160px;
  height: 225px;
  background: #0066ff;
  border-radius: 10px;
}

.dropdown-content a:hover {
  background-color: #0079ff;
}

.btn-submit-address {
  height: 32px;
  width: 220px;
  border: none;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  background-color: #0066ff;
}

.btn-submit-mapa {
  height: 32px;
  width: 220px;
  border: none;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  background-color: #0066ff;
}

.custom-select .vs__dropdown-toggle {
  background-color: white;
}

.custom-input {
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  width: 95%;
  cursor: pointer;
}

.header-content {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
</style>
