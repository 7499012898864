import request from "@/utils/aplication";
const obj = {
  getDataConfig(values: any) {
    return request({
      url: "/orchestatorv2/orchestator/dataList",
      method: "post",
      data: values,
    });
  },
  functionConfig(values: any) {
    return request({
      url: "/function",
      method: "post",
      data: values,
    });
  },
  getDataConfigv3(values: any) {
    return request({
      url: "/orchestatorv3/dataList",
      method: "post",
      data: values,
    });
  },
};
export default obj;
