import request from '@/utils/starlink'
const obj = {
  servicelinenew(page: string, limit: string, column:string, order:string, type:string) {
    return request({
      url: '/serviceline/dataGeneral?columnaOrden='+column+'&order='+order+'&searchData='+type,
      method: 'get'
    })
  },
  serviceplan(values: any) {
    return request({
      url: '/v1/application/select-plan-services',
      method: 'post',
      data: values
    })
  },
  customer(values: any) {
    return request({
      url: '/v1/application/get-subscribers',
      method: 'post',
      data: values
    })
  },

  serialnumber(values: any) {
    return request({
      url: '/v1/application/get-devices',
      method: 'post',
      data: values
    })
  },
}
export default obj