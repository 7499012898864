import request from "@/utils/incident";
import request2 from "@/utils/support";

const obj = {
  problemtype(values: any) {
    return request2({
      url: '/incident/listLogicStatus',
      method: 'post',
      data: values
    })
  },
  soporte(values: any) {
    return request({
      url: '/ticket/createDerivateNoc',
      method: 'post',
      data: values
    })
  },
  
  createdSupport(values: any) {
    return request2({
      url: '/incident/createTicket',
      method: 'post',
      data: values
    })
  },


  


};
export default obj;
